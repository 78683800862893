import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import { Container, Button } from "react-bootstrap";
import CategorySlider from "../Elements/CategorySlider";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../Elements/Footer";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { SERVER_URL } from "../../apiCall";
import Helmet from "react-helmet";
// import ExampleCarouselImage from "components/ExampleCarouselImage";

const Home = () => {
  const [setting, setSetting] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadSetting = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=settings&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setSetting(result[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSetting();
  }, []);

  useEffect(() => {
    if (setting) {
      // Update title and favicon using React Helmet

      document.title = setting.Website_Name;

      const link = document.querySelector('link[rel="icon"]');
      if (link) {
        link.href = setting.Website_Logo;
      } else {
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = setting.Website_Logo;
        document.head.appendChild(newLink);
      }
    }
  }, [setting]);

  return (
    <>
      <Container fluid className="hero">
        {loading ? (
          <CircularProgress />
        ) : (
          <Carousel>
            <Carousel.Item>
              <Link to={setting.Slide1Url}>
                <Image
                  className="heroImage"
                  src={SERVER_URL + setting.Slide1}
                  alt="Slide 1"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item>
              <Link to={setting.Slide2Url}>
                <Image
                  className="heroImage"
                  src={SERVER_URL + setting.Slide2}
                  alt="Slide 2"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item>
              <Link to={setting.Slide3Url}>
                <Image
                  className="heroImage"
                  src={SERVER_URL + setting.Slide3}
                  alt="Slide 3"
                />
              </Link>
            </Carousel.Item>
          </Carousel>
        )}
      </Container>
      <CategorySlider />
      {loading ? (
        <CircularProgress />
      ) : (
        <Container
          fluid
          className="customOrder d-flex align-items-centter justify-content-center"
        >
          {/* <h3 className="title2">Design Your Own Jewellery</h3> */}
          <Link className="d-flex align-items-center" to="/custom-order">
            <Button variant="success" className="customDesignBtn">
              Create My Customized Design
            </Button>
          </Link>
          <Image
            className="customDesignImage"
            src={SERVER_URL + setting.Custom_Design_Image}
          />
        </Container>
      )}

      <Footer />
    </>
  );
};

export default Home;
