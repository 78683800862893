import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { MdModeEdit } from "react-icons/md";
import { RiEyeLine } from "react-icons/ri";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import { MdDeleteOutline } from "react-icons/md";
import { SERVER_URL, api } from "../../apiCall";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Dialog,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Category = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [bannerToUpload, setBannerToUpload] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [open, setOpen] = useState(false);
  const [selcetedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState(null);
  const [selectedCategoryBanner, setSelectedCategoryBanner] = useState(null);
  const [categoryImageToUpload, setCategoryImageToUpload] = useState(null);
  const [categoryBannerToUpload, setCategoryBannerToUpload] = useState(null);

  const getAllCategories = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=category&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setRows(result);
        setAllCategories(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const columns = [
    {
      field: "categoryId",
      headerName: "Category Id",
      width: 250,
    },
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div style={{ cursor: "pointer", marginRight: "15px" }}>
            <RiEyeLine size={25} onClick={() => handleClickOpen(params.row)} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <MdDeleteOutline
              size={25}
              onClick={() => deleteCategory(params.row)}
            />
          </div>
        </>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImageToUpload(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerChange = (e) => {
    const bannerfile = e.target.files[0];

    if (bannerfile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedBanner(e.target.result);
        setBannerToUpload(bannerfile);
      };
      reader.readAsDataURL(bannerfile);
    }
  };

  const createNewCategory = async () => {
    let formData = new FormData();

    formData.append("method", "category");
    formData.append("action", "insert");

    let categoryName = document.getElementById("category-name").value;
    formData.append("categoryName", categoryName);

    // Ensure that selectedImage is a base64-encoded image string
    if (imageToUpload) {
      formData.append("categoryImage", imageToUpload);
    }

    if (bannerToUpload) {
      formData.append("categoryBanner", bannerToUpload);
    }

    fetch("http://visionelectricalsystems.com/api.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setSelectedImage(null);
          setSelectedBanner(null);
          document.getElementById("category-name").value = "";
          setLoading(true);
          getAllCategories();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClickOpen = (row_data) => {
    let categoryFound = allCategories.find(
      (cat) => cat.Category_Id === row_data.categoryId
    );
    if (categoryFound) {
      console.log(categoryFound, "here");
      setSelectedCategory(categoryFound);
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (isEditing) {
      const userConfirmed = window.confirm(
        "Your changes might not be saved. Do you wish to exit?"
      );
      if (userConfirmed) {
        setOpen(false);
        setIsEditing(false);
      }
    } else {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setReadOnly(false);
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    // Perform save logic here
    let formData = new FormData();

    formData.append("method", "category");
    formData.append("action", "update");

    let categoryId = document.getElementById("category_id").value;
    formData.append("categoryId", categoryId);

    let categoryName = document.getElementById("category_name").value;
    formData.append("categoryName", categoryName);

    if (categoryImageToUpload) {
      formData.append("categoryImage", categoryImageToUpload);
    }

    if (categoryBannerToUpload) {
      formData.append("categoryBanner", categoryBannerToUpload);
    }

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      getAllCategories();
    }
    setIsEditing(false);
    setOpen(false);
    setReadOnly(true);
  };

  const handleCategoryImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedCategoryImage(e.target.result);
        setCategoryImageToUpload(file);

        document.getElementById("default-category-image").style.display =
          "none";
        document.getElementById("selected-category-image").style.display =
          "block";
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCategoryBannerChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedCategoryBanner(e.target.result);
        setCategoryBannerToUpload(file);

        document.getElementById("default-category-banner").style.display =
          "none";
        document.getElementById("selected-category-banner").style.display =
          "block";
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteCategory = async (row_data) => {
    const confirmDelete = window.confirm(
      "Do you want to delete this category?"
    );
    if (confirmDelete) {
      let formData = new FormData();
      formData.append("method", "category");
      formData.append("action", "delete");
      formData.append("categoryId", row_data.categoryId);

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status === true) {
        getAllCategories();
      } else {
        alert("Some Error Occured");
      }
    }
  };
  return (
    <>
      <Grid container className="mt-25">
        <Card>
          <h5 className="mx-3 my-3">Category</h5>
          <p className="mx-3 my-3">Add, Edit or delete a Category</p>
          <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
          <CardContent style={{ display: "flex" }}>
            <div xs={12} md={4}>
              <Card
                style={{
                  width: "300px",
                  marginRight: "20px",
                }}
              >
                <h5 className="mx-3 my-3 d-flex align-self-center">
                  Add Category
                </h5>
                <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
                <CardContent>
                  {/* <TextField
                    id="outlined-basic"
                    label="Category Id"
                    variant="outlined"
                    className="mb-3"
                    fullWidth
                  /> */}

                  <TextField
                    id="category-name"
                    label="Category Name"
                    variant="outlined"
                    className="mb-3"
                    fullWidth
                  />
                  <FormControl fullWidth className="d-flex align-items-center">
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Image Selected"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        src={require("../../Asset/upload.png")}
                        alt="Category Image"
                      />
                    )}
                    <Input
                      id="image-picker"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="image-picker">
                      {selectedImage ? (
                        <Button component="span" variant="outlined">
                          Change Image
                        </Button>
                      ) : (
                        <Button component="span" variant="outlined">
                          Upload Image
                        </Button>
                      )}
                    </label>

                    {selectedBanner ? (
                      <img
                        src={selectedBanner}
                        alt="Banner Selected"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        src={require("../../Asset/upload.png")}
                        alt="Category Banner"
                      />
                    )}
                    <Input
                      id="banner-picker"
                      type="file"
                      onChange={handleBannerChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="banner-picker">
                      {selectedBanner ? (
                        <Button component="span" variant="outlined">
                          Change Banner
                        </Button>
                      ) : (
                        <Button component="span" variant="outlined">
                          Upload Banner
                        </Button>
                      )}
                    </label>

                    <Button variant="outlined" onClick={createNewCategory}>
                      SUBMIT
                    </Button>
                  </FormControl>
                </CardContent>
              </Card>
            </div>

            <div xs={12} md={8}>
              <Card sx={{ height: "100%", width: "100%" }}>
                {!loading ? (
                  <DataGrid
                    rows={rows.map((row) => ({
                      id: row.Id,
                      categoryId: row.Category_Id,
                      categoryName: row.Category_Name,
                    }))} // Assuming "Id" is the unique identifier
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                ) : (
                  <CircularProgress />
                )}
              </Card>
            </div>
          </CardContent>
        </Card>
      </Grid>

      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Product
              </Typography>
              <div className="">
                {isEditing ? (
                  <Button autoFocus color="inherit" onClick={handleSaveClick}>
                    Save
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <List>
            <Card>
              <ListItem className="m-3 d-flex justify-content-between">
                {selcetedCategory && selcetedCategory.Category_Id ? (
                  <TextField
                    id="category_id"
                    label="Category Id"
                    defaultValue={selcetedCategory.Category_Id}
                    InputProps={{
                      disabled: true,
                    }}
                    size="small"
                  />
                ) : null}

                {selcetedCategory && selcetedCategory.Category_Name ? (
                  <TextField
                    id="category_name"
                    label="Category Name"
                    defaultValue={selcetedCategory.Category_Name}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    size="small"
                  />
                ) : null}

                {selcetedCategory && selcetedCategory.Category_Image ? (
                  <div className="d-flex flex-column my-5 mx-2">
                    <img
                      src={
                        selcetedCategory.Category_Image
                          ? SERVER_URL + selcetedCategory.Category_Image
                          : require("../../Asset/upload.png")
                      }
                      alt="Selected"
                      style={{ maxWidth: "150px" }}
                      id="default-category-image"
                    />

                    <img
                      src={
                        selectedCategoryImage
                          ? selectedCategoryImage
                          : require("../../Asset/upload.png")
                      }
                      alt="Selected"
                      style={{ maxWidth: "150px", display: "none" }}
                      id="selected-category-image"
                    />

                    <Input
                      id="cat-image"
                      type="file"
                      onChange={handleCategoryImageChange}
                      disabled={readOnly}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="cat-image">
                      <Button
                        component="span"
                        variant="outlined"
                        className="mt-2"
                        disabled={readOnly}
                      >
                        Change Image
                      </Button>
                    </label>
                  </div>
                ) : null}
              </ListItem>
              <ListItem>
                {selcetedCategory && selcetedCategory.Category_Banner ? (
                  <div className="d-flex flex-column my-5 mx-2">
                    <img
                      src={
                        selcetedCategory.Category_Banner
                          ? SERVER_URL + selcetedCategory.Category_Banner
                          : require("../../Asset/upload.png")
                      }
                      alt="Selected"
                      style={{ maxWidth: "150px" }}
                      id="default-category-banner"
                    />

                    <img
                      src={
                        selectedCategoryBanner
                          ? selectedCategoryBanner
                          : require("../../Asset/upload.png")
                      }
                      alt="Selected"
                      style={{ maxWidth: "150px", display: "none" }}
                      id="selected-category-banner"
                    />

                    <Input
                      id="cat-banner"
                      type="file"
                      onChange={handleCategoryBannerChange}
                      disabled={readOnly}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="cat-banner">
                      <Button
                        component="span"
                        variant="outlined"
                        className="mt-2"
                        disabled={readOnly}
                      >
                        Change Banner
                      </Button>
                    </label>
                  </div>
                ) : null}
              </ListItem>
            </Card>
          </List>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Category;
