import React, { useState } from "react";
import axios from "axios"; // Or any preferred HTTP library

const UploadExcel = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("excel_file", file);

      setUploadStatus("Uploading...");
      setUploadStatus("Uploading...");

      const response = await axios.post(
        "http://visionelectricalsystems.com/api.php?method=upload_excel",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUploadStatus("Uploaded Successfully"); // Assuming API returns a success/error message
    } catch (error) {
      console.error(error);
      setUploadStatus("Upload failed");
    }
  };

  return (
    <div className="m-5">
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} className="mb-50" />
        <button type="submit" className="my-3">
          Upload
        </button>
        {uploadStatus && <p>{uploadStatus}</p>}
      </form>
    </div>
  );
};
export default UploadExcel;
