import React, { useState, useEffect } from "react";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { SiElementary } from "react-icons/si";
import { CgShapeHexagon } from "react-icons/cg";
import { MdPerson } from "react-icons/md";
import { RiMenu2Fill } from "react-icons/ri";
import { Nav, Navbar, Container, Row, Col } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Badge from "@mui/material/Badge";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaSignOutAlt } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaSignInAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../apiCall";
import CircularProgress from "@mui/material/CircularProgress";
import { CgProfile } from "react-icons/cg";

function Header(props) {
  const [setting, setSetting] = useState(null);
  const fromCategory = "false";
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false"
  );
  const [loading, setLoading] = useState(true);

  const loadSetting = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=settings&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setSetting(result[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSetting();
  }, []);

  const handleRedirection = () => {
    if (isLoggedIn === "true") {
      localStorage.clear();
      window.location.href = "/";
    } else {
      window.location.href = "/login";
    }
  };

  const handleRedirectToShop = () => {
    // Add category and shape as query parameters when redirecting to Shop
    navigate("/shop?fromCategory=" + fromCategory);
  };

  const navigateToCart = () => {
    window.location.href = "/cart";
  };
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Navbar
          sticky="top"
          bg="light"
          collapseOnSelect
          expand="lg"
          className=" bg-body-tertiary"
        >
          <Container fluid>
            <Navbar.Brand style={{ paddingLeft: "20px" }}>
              <Link to="/">
                <img
                  src={SERVER_URL + setting.Website_Logo}
                  width="100"
                  height="70"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <RiMenu2Fill />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-center flex-grow-1">
                <Nav.Link>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: 25,
                      paddingRight: "50px",
                      fontFamily: "Quicksand",
                      fontWeight: 700,
                    }}
                  >
                    HOME
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <div
                    // onClick={() => handleRedirectToShop()}
                    // to="/shop"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: 25,
                      paddingRight: "50px",
                      fontFamily: "Quicksand",
                      fontWeight: 700,
                    }}
                  >
                    CATEGORY
                  </div>
                </Nav.Link>
                <Nav.Link>
                  <div
                    onClick={() => handleRedirectToShop()}
                    // to="/shop"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: 25,
                      paddingRight: "50px",
                      fontFamily: "Quicksand",
                      fontWeight: 700,
                    }}
                  >
                    NEW ARRIVALS
                  </div>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to="/aboutus"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: 25,
                      fontFamily: "Quicksand",
                      fontWeight: 700,
                    }}
                  >
                    ABOUT US
                  </Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Nav style={{ display: "flex" }}>
              <Nav.Link>
                <Link
                  to="/cart"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Quicksand",
                    fontWeight: 700,
                  }}
                >
                  <Badge
                    badgeContent={props.countInCart}
                    max={10}
                    color="success"
                    className="mx-1"
                  >
                    <HiMiniShoppingBag
                      size={30}
                      color="Black"
                      className="headerIcon "
                      style={{ marginBottom: "6px" }}
                    />
                  </Badge>
                </Link>
              </Nav.Link>
              <span
                style={{
                  textDecoration: "none",
                  color: "black !important",
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Quicksand",
                  fontWeight: 700,
                }}
              >
                {isLoggedIn === "true" ? (
                  <NavDropdown
                    title="ACCOUNT"
                    id="basic-nav-dropdown"
                    className="text-dark dropdown-left"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: 25,
                      fontFamily: "Quicksand",
                      fontWeight: 700,
                    }}
                  >
                    <NavDropdown.Item
                      style={{ paddingLeft: "14px" }}
                      className="py-2"
                    >
                      <Link to="/myaccount">
                        <FaUser style={{ color: "#3bb77e" }} /> My Account
                      </Link>
                    </NavDropdown.Item>
                    {/*  */}

                    <NavDropdown.Item
                      href="#action/3.3"
                      onClick={handleRedirection}
                    >
                      <FaSignOutAlt style={{ color: "#3bb77e" }} /> Sign Out
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <>
                    <Nav.Link>
                      <Link
                        to="/login"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: 25,
                          fontFamily: "Quicksand",
                          fontWeight: 700,
                        }}
                      >
                        <CgProfile
                          size={30}
                          color="Black"
                          style={{ marginBottom: "6px" }}
                        />
                      </Link>
                    </Nav.Link>
                  </>
                )}
              </span>
              {/* <Nav.Link>
                <Link
                  to="/admin"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: 25,
                    fontFamily: "Quicksand",
                    fontWeight: 700,
                  }}
                >
                  ADMIN
                </Link>
              </Nav.Link> */}
            </Nav>
          </Container>
        </Navbar>
      )}

      <Outlet />
    </>
  );
}

export default Header;
