import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddProduct from "./AddProduct"; // Import your AddProduct component
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { DataGrid } from "@mui/x-data-grid";
import { RiEyeLine } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import Input from "@mui/material/Input";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { MdDeleteOutline } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import UploadExcel from "./UploadExcel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ExcelTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Products = ({ setAddProductVisible, addProductVisible }) => {
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState("All Combinations");

  useEffect(() => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=get_all_combination"
    )
      .then((response) => response.json())
      .then((data) => {
        const combinations =
          selectedValue === "Unique Products"
            ? data["Unique Combination"]
            : data["All Combination"];
        setColumns([
          {
            field: "productId",
            headerName: "Product Id",
            width: 150,
          },
          {
            field: "combinationId",
            headerName: "Combination Id",
            width: 250,
          },
          {
            field: "productName",
            headerName: "Product Name",
            width: 250,
          },
          {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => (
              <div
              // style={{
              //   width: "100px",
              //   height: "25px",
              //   borderRadius: "15%",
              //   backgroundColor: "Green",
              // }}
              >
                Active
              </div>
            ),
          },
          // {
          //   field: "action",
          //   headerName: "Action",
          //   width: 150,
          //   renderCell: (params) => (
          //     <>
          //       <div
          //         onClick={handleClickOpen}
          //         style={{ cursor: "pointer", marginRight: "15px" }}
          //       >
          //         <RiEyeLine size={25} />
          //       </div>
          //       <div
          //         // onClick={deleteItem(params.rows.combinationId)}
          //         style={{ cursor: "pointer" }}
          //       >
          //         <MdDeleteOutline size={25} />
          //       </div>
          //     </>
          //   ),
          // },
        ]);
        setRows(
          combinations.map((row) => ({
            id: row.Id,
            productId: row.Product_Id,
            combinationId: row.Combination_Id,
            productName: row.Product_Name,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [selectedValue]);

  // const columns = [
  //   {
  //     field: "productId",
  //     headerName: "Product Id",
  //     width: 150,
  //   },
  //   {
  //     field: "combinationId",
  //     headerName: "Combination Id",
  //     width: 250,
  //   },
  //   {
  //     field: "productName",
  //     headerName: "Product Name",
  //     width: 250,
  //   },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     width: 150,
  //     renderCell: (params) => (
  //       <div
  //       // style={{
  //       //   width: "100px",
  //       //   height: "25px",
  //       //   borderRadius: "15%",
  //       //   backgroundColor: "Green",
  //       // }}
  //       >
  //         Active
  //       </div>
  //     ),
  //   },
  //   {
  //     field: "action",
  //     headerName: "Action",
  //     width: 150,
  //     renderCell: (params) => (
  //       <>
  //         <div
  //           onClick={handleClickOpen}
  //           style={{ cursor: "pointer", marginRight: "15px" }}
  //         >
  //           <RiEyeLine size={25} />
  //         </div>
  //         <div onClick={deleteItem} style={{ cursor: "pointer" }}>
  //           <MdDeleteOutline size={25} />
  //         </div>
  //       </>
  //     ),
  //   },
  // ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // const deleteItem = (selectedCombinationId) => {
  //   if (selectedValue === "Unique Combinations") {
  //     let url =
  //       "http://visionelectricalsystems.com/api.php?method=product&&action=deleteUniqueCombination";
  //     fetch(url + `&&combinationId=${selectedCombinationId}`, {
  //       method: "DELETE",
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.success) {
  //           // Successfully deleted, update the rows state
  //           alert("Deleted Successfully");
  //         } else {
  //           alert("Failed to delete combination. Please try again.");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error deleting combination:", error);
  //         alert("An error occurred while deleting combination.");
  //       });
  //   } else if (selectedValue === "All Combinations") {
  //     let url =
  //       "http://visionelectricalsystems.com/api.php?method=product&&action=deleteCombination";
  //   } else {
  //     let url =
  //       "http://visionelectricalsystems.com/api.php?method=product&&action=deleteProduct";
  //   }
  // };

  const handleAddProductClick = () => {
    setAddProductVisible(true);
  };

  const handleUploadExcelClick = () => {
    setOpenExcel(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenExcel(false);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setReadOnly(false);
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Perform save logic here
    setIsEditing(false);
    setOpen(false);
    setReadOnly(true);
  };

  return (
    <>
      {addProductVisible ? (
        <AddProduct />
      ) : (
        <>
          <Grid className="my-4 d-flex flex-column">
            <Card>
              <h5 className="mx-3 my-3">Product List</h5>
              <p className="mx-3">Manage your Products</p>
              <hr style={{ color: "grey", margin: "0px" }} />
              <CardContent className="d-flex align-items-center justify-content-between py-3">
                <Grid className="d-flex align-items-center">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Product"
                    defaultValue="All Combinations"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <MenuItem key="All Combinations" value="All Combinations">
                      All Combinations
                    </MenuItem>
                    <MenuItem key="Unique Products" value="Unique Products">
                      Unique Products
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    onClick={handleUploadExcelClick}
                    style={{ marginRight: "25px" }}
                  >
                    Upload Product
                  </Button>
                  <Button variant="contained" onClick={handleAddProductClick}>
                    Add Product
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid>
            <Card
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!loading ? (
                <DataGrid
                  rows={rows} // Assuming "Id" is the unique identifier
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 25, 100]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              ) : (
                <CircularProgress />
              )}
            </Card>
          </Grid>

          {/* view product */}
          <React.Fragment>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Product
                  </Typography>
                  <div className="">
                    {isEditing ? (
                      <Button
                        autoFocus
                        color="inherit"
                        onClick={handleSaveClick}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        autoFocus
                        color="inherit"
                        onClick={handleEditClick}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </Toolbar>
              </AppBar>
              <List>
                <ListItem className="m-3 d-flex justify-content-between">
                  <TextField
                    id="outlined-read-only-input"
                    label="Product Id"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Combination Id"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="SKU Id"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Style"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </ListItem>
                <ListItem className="m-3 d-flex justify-content-between">
                  <TextField
                    id="outlined-read-only-input"
                    label="Product Name"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Diamond Quality"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Center Stone Carat"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Stone Carat"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </ListItem>
                <ListItem className="m-3 d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <img
                      src={require("../../Asset/upload.png")}
                      alt="Selected"
                      style={{ maxWidth: "150px" }}
                    />
                    <Input
                      id="image-picker"
                      type="file"
                      // onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <Button component="span" variant="outlined">
                      Change Image
                    </Button>
                  </div>
                  <div className="d-flex flex-column align-items-center ml-100">
                    <span
                      style={{
                        marginBottom: "25px",
                        width: "90px",
                        height: "90px",
                        // backgroundColor: metalData.metalColor,
                        backgroundColor: "RED",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      14K
                      {/* {metalData.metalCarat} */}
                    </span>
                    <TextField
                      id="outlined-select"
                      select
                      label="Metal"
                      defaultValue="Metal"
                      // size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                    >
                      <MenuItem key="Metal" value="Metal">
                        Rose Gold, 14K
                      </MenuItem>
                    </TextField>
                  </div>

                  <TextField
                    id="outlined-read-only-input"
                    label="Stone Origin"
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Product Price"
                    defaultValue="Product Price"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </ListItem>
              </List>
            </Dialog>
          </React.Fragment>

          {/* Upload Excel */}
          <React.Fragment>
            <Dialog
              fullScreen
              open={openExcel}
              onClose={handleClose}
              TransitionComponent={ExcelTransition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Upload Excel
                  </Typography>
                </Toolbar>
              </AppBar>
              <UploadExcel />
            </Dialog>
          </React.Fragment>
        </>
      )}
    </>
  );
};

export default Products;
