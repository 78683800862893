import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IoMdSettings } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { FaFileArchive } from "react-icons/fa";
import { CiLight } from "react-icons/ci";
import { FaShop } from "react-icons/fa6";
import { MdSpaceDashboard } from "react-icons/md";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { IoShapes } from "react-icons/io5";
import { MdAcUnit } from "react-icons/md";
import Dashboard from "../Admin/Dashboard";
import Shape from "../Admin/Shape";
import { GiCutDiamond } from "react-icons/gi";
import Products from "./Products";
import Category from "./Category";
import Metal from "./Metal";
import Style from "./Style";
import Files from "./Files";
import Orders from "./Orders";
import Users from "./Users";
import Setting from "./Setting";
import { CircularProgress } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  backgroundColor: "#fff",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#fff",
  boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.03)",
  borderBottom: "1px solid #eee",
  color: "black",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#fff",
  boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.03)",
  borderBottom: "1px solid #eee",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Admin = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [dashboardVisible, setDashboardVisible] = useState(true);
  const [productVisible, setProductVisible] = useState(false);
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [shapeVisible, setShapeVisible] = useState(false);
  const [metalVisible, setMetalVisible] = useState(false);
  const [styleVisible, setStyleVisible] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);
  const [userVisible, setUserVisible] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [settingData, setSettingData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadSetting = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=settings&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setSettingData(result[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSetting();
  }, []);

  const dropdown = () => {
    setShowOptions(!showOptions);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDashboardClick = () => {
    setDashboardVisible(true);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setShowOptions(false);
    setSettingVisible(false);
  };

  const ProductClick = () => {
    setDashboardVisible(false);
    setProductVisible(true);
    setAddProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setSettingVisible(false);
  };
  const CategoryClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(true);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setSettingVisible(false);
  };
  const ShapeClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(true);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setSettingVisible(false);
  };
  const MetalClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(true);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setSettingVisible(false);
  };
  const StyleClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(true);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setSettingVisible(false);
  };
  const FileClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(true);
    setOrderVisible(false);
    setUserVisible(false);
    setSettingVisible(false);
  };
  const OrderClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(true);
    setUserVisible(false);
    setShowOptions(false);
    setSettingVisible(false);
  };
  const userClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(true);
    setShowOptions(false);
    setSettingVisible(false);
  };
  const settingClick = () => {
    setDashboardVisible(false);
    setProductVisible(false);
    setCategoryVisible(false);
    setShapeVisible(false);
    setMetalVisible(false);
    setStyleVisible(false);
    setFileVisible(false);
    setOrderVisible(false);
    setUserVisible(false);
    setShowOptions(false);
    setSettingVisible(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography
                style={{ cursor: "pointer" }}
                variant="h6"
                noWrap
                component="div"
              >
                {settingData.Website_Name}
              </Typography>
            )}
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          className="d-flex justify-content-between"
          sx={{ paddingLeft: 2 }}
        >
          <Typography variant="h5" noWrap component="div">
            DASHBOARD
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider
          style={{
            boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.03)",
            borderBottom: "1px solid #eee",
            backgroundColor: "grey",
          }}
        />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            {/* dashboard */}
            <ListItemButton
              onClick={handleDashboardClick}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: dashboardVisible ? "lightgray" : "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <MdSpaceDashboard color="black" size={25} />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>

            {/* Store */}
            <ListItemButton
              onClick={dropdown}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FaShop size={25} color="black" />
              </ListItemIcon>
              <ListItemText primary="Store" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

            {/* options */}
            {showOptions && (
              <>
                {/* product */}
                <ListItemButton
                  onClick={ProductClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    // marginLeft: "20px",
                    backgroundColor: productVisible
                      ? "lightgray"
                      : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <GiCutDiamond size={25} color="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Product"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                {/* category */}
                <ListItemButton
                  onClick={CategoryClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    // marginLeft: "20px",
                    backgroundColor: categoryVisible
                      ? "lightgray"
                      : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <BiSolidCategoryAlt size={25} color="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Category"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                {/* shape */}
                <ListItemButton
                  onClick={ShapeClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    // marginLeft: "20px",
                    backgroundColor: shapeVisible ? "lightgray" : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <IoShapes size={25} color="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Shape"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                {/* metal */}
                <ListItemButton
                  onClick={MetalClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    // marginLeft: "20px",
                    backgroundColor: metalVisible ? "lightgray" : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <MdAcUnit size={25} color="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Metal"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                {/* Style */}
                <ListItemButton
                  onClick={StyleClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    // marginLeft: "20px",
                    backgroundColor: styleVisible ? "lightgray" : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CiLight size={25} color="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Style"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>

                {/* File */}
                {/* <ListItemButton
                  onClick={FileClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    marginLeft: "20px",
                    backgroundColor: fileVisible ? "lightgray" : "transparent",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FaFileArchive size={25} color="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Files"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton> */}
              </>
            )}

            {/* Orders */}
            <ListItemButton
              onClick={OrderClick}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: orderVisible ? "lightgray" : "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BsFillBoxSeamFill color="black" size={25} />
              </ListItemIcon>
              <ListItemText primary="Orders" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

            {/* Users */}
            <ListItemButton
              onClick={userClick}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: userVisible ? "lightgray" : "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FaUser color="black" size={25} />
              </ListItemIcon>
              <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

            {/* divider */}
            <Divider
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.03)",
                borderBottom: "1px solid #eee",
                backgroundColor: "grey",
              }}
            />

            {/* Settings */}
            <ListItemButton
              onClick={settingClick}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: settingVisible ? "lightgray" : "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <IoMdSettings color="black" size={25} />
              </ListItemIcon>
              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: "#F8F9FA" }}
      >
        <DrawerHeader
          style={{
            minHeight: "40px",
          }}
        />
        {dashboardVisible && <Dashboard />}
        {productVisible && (
          <Products
            setAddProductVisible={setAddProductVisible}
            addProductVisible={addProductVisible}
          />
        )}
        {categoryVisible && <Category />}
        {shapeVisible && <Shape />}
        {metalVisible && <Metal />}
        {styleVisible && <Style />}
        {fileVisible && <Files />}
        {orderVisible && <Orders />}
        {userVisible && <Users />}
        {settingVisible && <Setting />}
      </Box>
    </Box>
  );
};

export default Admin;
