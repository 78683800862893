import React, { useEffect, useState } from "react";
import RangeSlider from "../Design/Rangeslider";
import { api, SERVER_URL } from "../../apiCall";
import StarRating from "../Design/StarRating";
import Footer from "../Elements/Footer";
import ToastMsg from "../Design/ToastMsg";
import { json, Link, useLocation } from "react-router-dom";
import { Form, FormControl, Button } from "react-bootstrap";
import Drawer from "@mui/material/Drawer";
// import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import { CircularProgress } from "@mui/material";

function Shop(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromCategory = queryParams.get("fromCategory");
  const fromShape = queryParams.get("fromShape");
  const fromStyle = queryParams.get("fromStyle");
  const category = queryParams.get("category");
  const shape = queryParams.get("shape");
  const style = queryParams.get("style");

  const [loading, setLoading] = useState(true);
  const [allShape, setAllShape] = useState(true);
  const [allStyle, setAllStyle] = useState(true);

  const [allProducts, setAllProducts] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [metal, setMetal] = useState(null);
  const [stone, setStone] = useState(null);
  const [stoneShape, setStoneShape] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [msg, setMsg] = useState(null);
  const [currentProductB64, setCurrentProductB64] = useState(null);
  const [uniqueMetalsByProductId, setUniqueMetalsByProductId] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState("false");
  const [selectedMetals, setSelectedMetals] = useState({});
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const openSelectedProduct = (product) => {
    let productData = btoa(JSON.stringify(product));
    // window.location.href = "/product?product=" + productData;
    setCurrentProductB64(productData);
    document
      .getElementById("currentProdLink")
      .setAttribute("href", `product?product=${productData}`);
    console.log(document.getElementById("currentProdLink"), "link");

    document.getElementById("currentProdLink").click();
    // alert("hello");
    // // Convert product data to base64
    // let productData = btoa(JSON.stringify(product));
    // // Navigate to the product detail page with the encoded product data
    // navigate(`/product?product=${productData}`);
  };

  const getInitialProducts = async () => {
    //1. All products api call:
    let productApiResponse = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=get_all_combination"
    );

    // console.log(productApiResponse["Unique Combination"], "prod");

    console.log(uniqueMetalsByProductId);
    setAllProducts(productApiResponse["Unique Combination"]);
    setLoading(false);

    productApiResponse["All Combination"].forEach((combination) => {
      const { Product_Id, Carat, Metal_Color, Cover_Image, Product_Price } =
        combination;

      // Initialize array if it doesn't exist
      if (!uniqueMetalsByProductId[Product_Id]) {
        uniqueMetalsByProductId[Product_Id] = [];
      }

      // Check if the array already contains an object with the same Carat and Metal_Color
      const alreadyExists = uniqueMetalsByProductId[Product_Id].some(
        (metal) => metal.Carat === Carat && metal.Metal_Color === Metal_Color
      );

      // Add metal to the array if it's not already present
      if (!alreadyExists) {
        uniqueMetalsByProductId[Product_Id].push({
          Carat,
          Metal_Color,
          Cover_Image,
          Product_Price,
        });
      }
    });

    //2. All categories api call:
    // let categoryApiResponse = await api(
    //   "GET",
    //   "http://visionelectricalsystems.com/api.php?method=category&action=getAll"
    // );
    // setAllCategories(categoryApiResponse.result);
  };

  // const openSelectedProduct = (product) => {
  //   // Convert product data to base64
  //   let productData = btoa(JSON.stringify(product));

  //   // Use the history.push method to navigate and pass state
  //   navigate("/product", { state: { product: productData } });
  // };

  // useEffect(() => {
  //   if (fromCategory === "false") {
  //     // getInitialProducts();
  //   }
  // }, []);

  // const addToCart = (product) => {
  //   let currentCart = [];
  //   product["count_in_cart"] = 1;
  //   let total_in_cart = 1;
  //   if (localStorage.getItem("currentCart")) {
  //     currentCart = JSON.parse(localStorage.getItem("currentCart"));

  //     //Check for existing product:
  //     const existingProduct = currentCart.find(
  //       (item) => item.Product_Id === product.Product_Id
  //     );

  //     if (existingProduct) {
  //       setSeverity("info");
  //       setMsg("Product already exist in cart.");
  //       setShowToast(true);
  //     } else {
  //       currentCart.push(product);
  //       if (localStorage.getItem("total_in_cart")) {
  //         total_in_cart = parseInt(localStorage.getItem("total_in_cart"));
  //         total_in_cart += 1;
  //       }
  //       localStorage.setItem("total_in_cart", total_in_cart);
  //       props.setCountInCart(total_in_cart);
  //       setSeverity("success");
  //       setMsg("Product added to cart.");
  //       setShowToast(true);
  //     }
  //   } else {
  //     currentCart.push(product);
  //     localStorage.setItem("total_in_cart", total_in_cart);
  //     props.setCountInCart(total_in_cart);
  //   }
  //   localStorage.setItem("currentCart", JSON.stringify(currentCart));
  // };

  // useEffect(() => {
  //   // Set all products when the search term is empty
  //   if (!searchTerm.trim()) {
  //     setAllProducts(allProducts);
  //   } else {
  //     // Filter products based on the search term
  //     const filteredProducts = allProducts.filter((product) =>
  //       product.Product_Name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     // Update the state with filtered products
  //     setAllProducts(filteredProducts);
  //   }
  // }, [searchTerm, allProducts, setAllProducts]);

  useEffect(() => {
    // Call the function only if redirected from ShopByCategory
    if (fromCategory === "true" && fromShape === "true") {
      getProductsByCategoryAndShape(category, shape);
      // fromCategory = "false";
    } else if (fromCategory === "true" && fromStyle === "true") {
      getProductsByCategoryAndStyle(category, style);
      // fromCategory = "false";
    } else if (clearFilter === "true") {
      getInitialProducts();
      setClearFilter("false");
    } else {
      getInitialProducts();
    }
  }, [fromCategory, fromShape, fromStyle, category, shape, style, clearFilter]); // Include fromCategory, category, and shape in the dependency array

  useEffect(() => {
    getShape();
    getStyle();
    getCategory();
  }, []);
  const getProductsByCategoryAndShape = async (category, shape) => {
    const productsData = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=filter&action=categoryShape&&categoryId=" +
        category +
        "&shapeId=" +
        shape
    );

    setAllProducts(productsData.result);
    setLoading(false);

    // let categoryApiResponse = await api(
    //   "GET",
    //   "http://visionelectricalsystems.com/api.php?method=category&action=getAll"
    // );
    // setAllCategories(categoryApiResponse.result);
  };

  const getProductsByCategoryAndStyle = async (category, style) => {
    const productsData = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=filter&action=categoryStyle&&categoryId=" +
        category +
        "&styleId=" +
        style
    );

    setAllProducts(productsData.result);
    setLoading(false);

    // let categoryApiResponse = await api(
    //   "GET",
    //   "http://visionelectricalsystems.com/api.php?method=category&action=getAll"
    // );
    // setAllCategories(categoryApiResponse.result);
  };

  const getProductsByCategory = async (category) => {
    const productsData = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=filter&action=categoryName&categoryName=" +
        category.Category_Name
    );

    setAllProducts(productsData.result);
    setLoading(false);
  };

  const getProductsByStyle = async (style) => {
    const productsData = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=filter&action=style&styleId=" +
        style
    );

    setAllProducts(productsData.result);
  };

  const filterProducts = async () => {
    let filterString = "";
    if (metal) {
      filterString += "&action=metal&metal=" + metal;
    }
    if (stoneShape) {
      filterString += "&action=shape&shape=" + stoneShape;
    }
    if (stone) {
      filterString += "&action=stone&stone=" + stone;
    }

    const productsData = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=filter" + filterString
    );

    setAllProducts(productsData.result);
  };

  const setFilterValue = (filter, value) => {
    if (filter === "stone") {
      setStone(value);
    }
    if (filter === "stone_shape") {
      setStoneShape(value);
    }
    if (filter === "metal") {
      setMetal(value);
    }
  };

  const handleKeyPress = (e) => {
    // Prevent page refresh on Enter key press
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const getStyle = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=style&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setAllStyle(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const getShape = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=shape&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setAllShape(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const getCategory = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=category&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setAllCategories(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  // Function to receive min and max prices from child component
  const handleSliderChange = (values) => {
    setMin(values[0]);
    setMax(values[1]);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClearFilter = () => {
    setClearFilter("true");
  };

  const handleApplyFilter = async () => {
    const productsData = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=filter&action=priceRange&minPrice=" +
        min +
        "&&maxPrice=" +
        max
    );

    setAllProducts(productsData.result);
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <>
      <div className="container mb-30 mt-30">
        <div className="row flex-row-reverse">
          <div className="col-lg-4-5">
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <div className="shop-product-fillter">
                  <div className="totall-product">
                    {allProducts ? (
                      <>
                        <div className="d-flex align-items-center justify-content-between my-3">
                          <p>
                            We found{" "}
                            <strong className="text-brand">
                              {allProducts && allProducts.length}
                            </strong>{" "}
                            items for you!
                          </p>

                          <div className="sort-by-product-area d-flex align-items-center gap-5">
                            {/* filter for mobile */}
                            <div className="mobileFilter d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">
                              <span
                                style={{
                                  border: "1px solid #3bb77e",
                                  borderRadius: "10px",
                                  padding: "10px 20px",
                                  color: "grey",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                                onClick={handleDrawerOpen}
                              >
                                FILTER
                              </span>
                            </div>

                            <Form className="d-flex align-items-center">
                              <FormControl
                                type="text"
                                placeholder="Search"
                                className="mr-2"
                                value={searchTerm}
                                onChange={(event) =>
                                  setSearchTerm(event.target.value)
                                }
                                onKeyPress={handleKeyPress}
                              />
                            </Form>
                          </div>
                        </div>

                        <div className="row product-grid">
                          {/* Start Product Card */}
                          {allProducts &&
                            allProducts
                              .filter((product) => {
                                if (searchTerm === "") {
                                  //if query is empty
                                  return product;
                                } else {
                                  // Create a combined search string for relevant fields
                                  const searchableData = [
                                    product.Product_Name,
                                    product.Product_Description,
                                    product.Product_Style, // Add other fields as needed
                                  ].join(" ");

                                  // Check if any of the searchable fields match the search term (case-insensitive)
                                  return searchableData
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase());
                                }
                              })
                              .map((product) => (
                                <div
                                  className=" col-md-4 col-12 col-sm-6"
                                  key={product.Id}
                                >
                                  <div className="product-cart-wrap mb-30">
                                    <div className="product-img-action-wrap">
                                      <div className="product-img product-img-zoom">
                                        <Link
                                          to={
                                            "/product?product=" +
                                            btoa(JSON.stringify(product))
                                          }
                                        >
                                          {/* <a onClick={() => openSelectedProduct(product)}> */}

                                          {/* </a> */}
                                          {selectedMetals[product.Product_Id] &&
                                          selectedMetals[
                                            `${product.Product_Id}_color`
                                          ] &&
                                          uniqueMetalsByProductId[
                                            product.Product_Id
                                          ] ? (
                                            uniqueMetalsByProductId[
                                              product.Product_Id
                                            ]
                                              ?.filter(
                                                (metal) =>
                                                  metal.Carat ===
                                                    selectedMetals[
                                                      product.Product_Id
                                                    ] &&
                                                  metal.Metal_Color ===
                                                    selectedMetals[
                                                      `${product.Product_Id}_color`
                                                    ]
                                              )
                                              .map((metal, index) => (
                                                <>
                                                  <img
                                                    className="default-img first-default-img"
                                                    src={
                                                      SERVER_URL +
                                                      metal.Cover_Image
                                                    }
                                                    alt
                                                  />
                                                  <img
                                                    className="hover-img"
                                                    // src={SERVER_URL + metal.Cover_Image}
                                                    src={
                                                      "assets/imgs/shop/no-img.jpeg"
                                                    }
                                                    alt="Cover Image"
                                                  />
                                                </>
                                              ))
                                          ) : (
                                            <>
                                              <img
                                                className="default-img first-default-img"
                                                src={
                                                  SERVER_URL +
                                                  product.Cover_Image
                                                }
                                                alt
                                              />
                                              <img
                                                className="hover-img"
                                                // src={SERVER_URL + metal.Cover_Image}
                                                src={
                                                  "assets/imgs/shop/no-img.jpeg"
                                                }
                                                alt="Cover Image"
                                              />
                                            </>
                                          )}
                                        </Link>
                                      </div>

                                      {product.Product_Sale_Price !==
                                      product.Product_Price ? (
                                        <div className="product-badges product-badges-position product-badges-mrg">
                                          <span className="sale">Sale</span>
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="product-content-wrap">
                                      <div className="product-category">
                                        {/* <a onClick={() => openSelectedProduct(product)}> */}
                                        {product.Category_Name}
                                        {/* </a> */}
                                      </div>
                                      <Link
                                        to={
                                          "/product?product=" +
                                          btoa(JSON.stringify(product))
                                        }
                                      >
                                        <h2>
                                          {/* <a onClick={() => openSelectedProduct(product)}> */}
                                          {product.Product_Name}
                                          {/* </a> */}
                                        </h2>
                                      </Link>
                                      {/* <div className="product-rate-cover my-2">
                          Stone:
                          <span className="carat-span mx-2">
                            {product.Stone}
                          </span>
                        </div> */}
                                      {/* <div className="product-rate-cover">
                          <span>Metal: </span>
                          <span
                            className="color-circle mx-1 p-2"
                            style={{
                              backgroundColor: "#ffedab",
                              fontSize: "10px",
                            }}
                          >
                            14K
                          </span>
                        </div> */}
                                      <div className="shopMetal">
                                        {uniqueMetalsByProductId[
                                          product.Product_Id
                                        ]?.map((metal, index) => (
                                          <span
                                            key={index}
                                            className={`color-circle mx-1 p-2 ${
                                              selectedMetals[
                                                product.Product_Id
                                              ] === metal.Carat &&
                                              selectedMetals[
                                                `${product.Product_Id}_color`
                                              ] === metal.Metal_Color
                                                ? "selected-metal"
                                                : ""
                                            }`}
                                            style={{
                                              backgroundColor:
                                                metal.Metal_Color,
                                              fontSize: "10px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setSelectedMetals(
                                                (prevSelectedMetals) => ({
                                                  ...prevSelectedMetals,
                                                  [product.Product_Id]:
                                                    metal.Carat,
                                                  [`${product.Product_Id}_color`]:
                                                    metal.Metal_Color,
                                                })
                                              )
                                            }
                                          >
                                            {metal.Carat}
                                          </span>
                                        ))}
                                      </div>

                                      {/* <div className="product-rate-cover mt-2">
                          <strong>Shape: </strong>
                          <span>{shape.Shape_Name}</span>
                        </div> */}
                                      <div className="product-card-bottom">
                                        <div className="product-price">
                                          <span>
                                            {selectedMetals[
                                              product.Product_Id
                                            ] &&
                                            selectedMetals[
                                              `${product.Product_Id}_color`
                                            ] &&
                                            uniqueMetalsByProductId[
                                              product.Product_Id
                                            ] ? (
                                              uniqueMetalsByProductId[
                                                product.Product_Id
                                              ]
                                                ?.filter(
                                                  (metal) =>
                                                    metal.Carat ===
                                                      selectedMetals[
                                                        product.Product_Id
                                                      ] &&
                                                    metal.Metal_Color ===
                                                      selectedMetals[
                                                        `${product.Product_Id}_color`
                                                      ]
                                                )
                                                .map((metal, index) => (
                                                  <>{`$ ${metal.Product_Price}`}</>
                                                ))
                                            ) : (
                                              <>$ {product.Product_Price}</>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          {/*end product card*/}
                        </div>
                      </>
                    ) : (
                      <>
                        <h5 className="text-danger no-product-found-shop">
                          No Products found
                        </h5>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {/* pagination*/}
            {/* <div className="pagination-area mt-20 mb-20">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-start">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fi-rs-arrow-small-left" />
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item ">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link dot" href="#">
                      ...
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      6
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fi-rs-arrow-small-right" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
          </div>
          {/* Filter for big screens*/}
          <div className="col-lg-1-5 d-none d-xl-block primary-sidebar sticky-sidebar">
            <div className="sidebar-widget widget-category-2 mb-30">
              <ul className="d-flex align-items-center justify-content-center">
                <li className="d-none"></li>
                <li onClick={handleClearFilter} style={{ cursor: "pointer" }}>
                  Clear Filter
                </li>
                <li onClick={handleApplyFilter} style={{ cursor: "pointer" }}>
                  Apply Filter
                </li>
                <li className="d-none"></li>
              </ul>
              <h5 className="section-title style-1 mb-30">Price</h5>
              <RangeSlider onSliderChange={handleSliderChange} />
              <h5 className="section-title style-1 mb-30">Category</h5>
              <ul>
                {allCategories &&
                  allCategories.map((category) => (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        getProductsByCategory(category);
                      }}
                    >
                      <li>{category.Category_Name}</li>
                    </a>
                  ))}
              </ul>
              {/* <h5 className="section-title style-1 mb-30">Style</h5>
              <ul>
                {Array.isArray(allStyle) &&
                  allStyle.map((style) => (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        getProductsByStyle(style);
                      }}
                    >
                      <li>{style.Style_Name}</li>
                    </a>
                  ))}
              </ul>
              <h5 className="section-title style-1 mb-30">Shape</h5>
              <ul>
                {Array.isArray(allShape) &&
                  allShape.map((shape) => (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        // getProductsByCategory(category);
                      }}
                    >
                      <li>{shape.Shape_Name}</li>
                    </a>
                  ))}
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      {showToast && (
        <ToastMsg
          showToast={showToast}
          setShowToast={setShowToast}
          severity={severity}
          msg={msg}
        />
      )}
      <div>
        <Drawer
          open={open}
          onClose={handleDrawerClose}
          className="FilterDrawer"
        >
          <List>
            <ListItem button key="home">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button key="settings">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
        </Drawer>
      </div>

      <Footer />
    </>
  );
}

export default Shop;
