import React, { useEffect, useState } from "react";
import StarRating from "../Design/StarRating";
import Footer from "../Elements/Footer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import {
  Tooltip,
  Switch,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { swiffyslider } from "swiffy-slider";
import "swiffy-slider/css";
import { api, SERVER_URL } from "../../apiCall";
import { useLocation } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import ToastMsg from "../Design/ToastMsg";
import ThreeDHand from "../Elements/ThreeDHand";
import ReactImageMagnify from "react-image-magnify";

window.swiffyslider = swiffyslider;

window.addEventListener("load", () => {
  window.swiffyslider.init();
});

// import Swiffy Slider CSS

function Product(props) {
  const location = useLocation();
  const { product } = location.state || {};
  const [currentProduct, setCurrentProduct] = useState(null);
  const [shape, setShape] = useState(null);
  const [metal, setMetal] = useState();
  const [stoneCarat, setStoneCarat] = useState(null);
  const [stoneQuality, setStoneQuality] = useState(null);
  const [stoneOrigin, setStoneOrigin] = useState(null);
  const [centerStoneCarat, setCenterStoneCarat] = useState(null);
  const [price, setPrice] = useState(null);
  const [sizes, setSizes] = useState(null);
  const [productInformation, setProductInformation] = useState(null);
  const [productMeasurement, setProductMeasurement] = useState(null);
  const [showEngraving, setShowEngraving] = useState(false);
  const [showMatchingBand, setShowMatchingBand] = useState(false);
  const [showMatchingBandImage, setShowMatchingBandImage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [relatedProducts, setRelatedProduct] = useState(true);
  const [uniqueMetalsGlobal, setUniqueMetalsGlobal] = useState([]);
  const [matchingBandTaken, setMatchingBandTaken] = useState("No");
  const [activeCss, setActiveCss] = useState(false);
  const [relatedProductData, setRelatedProductData] = useState(null);
  const [ratingReviewData, setRatingReviewData] = useState(null);

  document.addEventListener("DOMContentLoaded", function () {
    const slider = document.querySelector(".slider-range");
    const handImage = document.getElementById("hand-image");

    slider.addEventListener("input", function () {
      const sliderValue = this.value;
      const r = this.value;
      let calcOpacity = "";

      const numberOfImages = 3; // Number of images you have
      const imageNumber = Math.min(
        Math.floor((sliderValue / 100) * numberOfImages) + 1,
        numberOfImages
      );
      if (imageNumber === 1) {
        calcOpacity = 1 - r * 0.01;
      } else if (imageNumber === 2) {
        calcOpacity = r * 0.02 + Math.floor(1 - r * 0.02) * (r - 90) * 0.04;
      } else if (imageNumber === 3) {
        calcOpacity = r * 0.02 - 0.5;
      }
      console.log("calcOpacity", calcOpacity);
      console.log("slidervalue", r);

      handImage.style.opacity = calcOpacity;
      handImage.src = `assets/images/hand-${imageNumber}.webp`;
    });
  });

  const showEngravingFunc = () => {
    setShowEngraving(!showEngraving);
  };

  const showMatchingBandFunc = () => {
    setShowMatchingBand(!showMatchingBand);
    setShowMatchingBandImage(!showMatchingBandImage);
    setMatchingBandTaken("Yes");
  };

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };

  const [stone, setStone] = useState(
    currentProduct ? currentProduct.Stone : ""
  );

  const [prongType, setProngType] = useState(
    currentProduct ? currentProduct.Prong_Type : ""
  );
  const [quality, setquality] = useState(
    currentProduct ? currentProduct.Stone_Quality : ""
  );

  const [productWeight, setProductWeight] = useState(
    currentProduct ? currentProduct.Product_Weight : ""
  );

  const [allCombinations, setAllCombinations] = useState(null);
  const [allShapes, setAllShapes] = useState(null);
  const [allMetals, setAllMetals] = useState(null);
  const [allStoneCarat, setAllStoneCarat] = useState(null);
  const [allDiamondQuality, setAllDiamondQuality] = useState(null);
  const [allOrigins, setAllOrigins] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [allCenterStoneCarats, setAllCenterStoneCarats] = useState(null);
  const [combinationVideo, setCombinationVideo] = useState(null);
  const [severity, setSeverity] = useState(null);

  const [msg, setMsg] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const base64String = urlParams.get("product");

    const productString = atob(base64String);
    const productObject = JSON.parse(productString);

    setCurrentProduct(productObject);
    setShape(productObject.Shape);
    setStone(productObject.Stone);
    setMetal(productObject.Metal);
    setStoneQuality(productObject.Stone_Quality);
    setStoneCarat(productObject.Stone_Carat);
    setProngType(productObject.Prong_Type);
    setStoneOrigin(productObject.Stone_Origin);
    setProductWeight(productObject.Product_Weight);
    setquality(productObject.Stone_Quality);
    setSizes(productObject.Size);
    setCenterStoneCarat(productObject.Center_Stone_Carat);
    setPrice(productObject.Product_Price);
    setProductInformation(productObject.Product_Information);
    setProductMeasurement(productObject.Product_Measurement);
    loadAllCombinations(productObject);
    // getProductImages(productObject);
  }, []);

  const loadAllCombinations = async (productObj) => {
    // Calling api get_combinations and set it to setAllCombinations:
    if (productObj) {
      let formData = new FormData();
      formData.append("productId", productObj.Product_Id);
      formData.append("method", "get_combination");
      formData.append("action", "getProductCombination");

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      const uniqueMetals = [];

      apiResponse["result"].forEach((combination) => {
        const { Carat, Metal_Id, Metal_Color, Metal_Name } = combination;

        // Check if the array already contains an object with the same Carat and Metal_Color
        const alreadyExists = uniqueMetals.some(
          (metal) =>
            metal.Carat === Carat &&
            metal.Metal_Color === Metal_Color &&
            metal.Metal_Name === Metal_Name &&
            metal.Metal_Id === Metal_Id
        );

        // Add metal to the array if it's not already present
        if (!alreadyExists) {
          uniqueMetals.push({
            Carat,
            Metal_Id,
            Metal_Color,
            Metal_Name,
          });
        }
      });

      setUniqueMetalsGlobal(uniqueMetals);

      setAllCombinations(apiResponse.result);
      setRelatedProductData(apiResponse.relatedProducts);
      setRatingReviewData(apiResponse.ratingsReview);

      let allCombination = apiResponse.result;

      let shapes = [];
      let metals = [];
      let metalsArray = [];
      let stoneCarats = [];
      let diamondQualities = [];
      let origins = [];
      let sizes = [];
      let centerStoneCarats = [];

      allCombination.forEach((combination) => {
        // if (!shapes.includes(combination.Shape)) {
        //   shapes.push(combination.Shape);
        //   console.log(shapes);
        // }
        if (!shapes.some((shapeObj) => shapeObj.Shape === combination.Shape)) {
          shapes.push({
            Shape: combination.Shape,
            Shape_Name: combination.Shape_Name,
            Shape_Image: combination.Shape_Image,
          });
          console.log("Shapes:", JSON.stringify(shapes, null, 2));
        }

        if (!metalsArray.includes(combination.Metal)) {
          metalsArray.push(combination.Metal);
        }

        if (!stoneCarats.includes(combination.Stone_Carat)) {
          stoneCarats.push(combination.Stone_Carat);
        }

        if (!diamondQualities.includes(combination.Stone_Quality)) {
          diamondQualities.push(combination.Stone_Quality);
        }

        if (!origins.includes(combination.Stone_Origin)) {
          origins.push(combination.Stone_Origin);
        }

        if (!centerStoneCarats.includes(combination.Center_Stone_Carat)) {
          centerStoneCarats.push(combination.Center_Stone_Carat);
        }
      });

      metalsArray.forEach((item) => {
        const [metalName, metalCarat] = item.split(",");
        const newMetalObject = {
          metal: metalName,
          carat: metalCarat,
        };
        metals.push(newMetalObject);
      });

      setAllShapes(shapes);
      // console.log(metals, "metals");
      setAllMetals(metals);
      setAllStoneCarat(stoneCarats);
      setAllDiamondQuality(diamondQualities);
      setAllOrigins(origins);
      setAllCenterStoneCarats(centerStoneCarats);
    }
  };

  const getProductImages = async (productObj) => {
    console.log(productObj, "img-obj");

    let formData = new FormData();
    formData.append("combinationId", productObj.Combination_Id);
    formData.append("method", "get_combination");
    formData.append("action", "getCombinationById");

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    apiResponse.result.forEach((obj) => {
      setProductImages(obj.Product_Images);
    });
  };

  const toggleBtnClass = (button) => {
    if (button === "natural") {
      document.getElementById("natural").classList.add("custom-btn-active");
      document
        .getElementById("lab-grown")
        .classList.remove("custom-btn-active");
      setStoneOrigin("Natural");
    }
    if (button === "lab-grown") {
      document.getElementById("lab-grown").classList.add("custom-btn-active");
      document.getElementById("natural").classList.remove("custom-btn-active");
      setStoneOrigin("Lab Grown");
    }
    if (button === "e+") {
      document.getElementById("e+").classList.add("custom-btn-active");
      document.getElementById("fg").classList.remove("custom-btn-active");
      setquality("E+, VSI+");
    }
    if (button === "fg") {
      document.getElementById("fg").classList.add("custom-btn-active");
      document.getElementById("e+").classList.remove("custom-btn-active");
      setquality("FG, VS2+");
    }
  };

  const toggleProngClass = (prongType) => {
    if (prongType === "4prong") {
      document.getElementById("4prong").classList.add("prong-span-active");
      document.getElementById("6prong").classList.remove("prong-span-active");
      setProngType("4 Prong");
    }
    if (prongType === "6prong") {
      document.getElementById("6prong").classList.add("prong-span-active");
      document.getElementById("4prong").classList.remove("prong-span-active");
      setProngType("6 Prong");
    }
  };

  const getShapeImgSrc = (shape) => {
    switch (shape) {
      case "Round":
      case "round":
        return "assets/imgs/shop/round.jpg";
      case "Oval":
      case "oval":
        return "assets/imgs/shop/oval.png";
      case "cushion":
      case "Cushion":
        return "assets/imgs/shop/cushion.jpg";
      case "radiant":
      case "Radiant":
        return "assets/imgs/shop/radiant.jpg";
      case "Trilliant":
      case "trilliant":
        return "assets/imgs/shop/trilliant.jpg";
      case "Princess":
      case "princess":
        return "assets/imgs/shop/princess.jpg";
      case "Asscher":
      case "asscher":
        return "assets/imgs/shop/asscher.jpg";
      case "Heart":
      case "heart":
        return "assets/imgs/shop/heart.jpg";
      case "Halo":
      case "halo":
        return "assets/imgs/shop/asscher.jpg";
      default:
        break;
    }
  };

  const getMetalBgColor = (metal) => {
    switch (metal) {
      case "Yellow Gold":
      case "Gold":
        return "#ffedab";
      case "Platinum":
      case "PLATINUM":
        return "#e5e4e2";
      case "Rose Gold":
        return "#B76E79";
      case "White Gold":
      case "Silver":
        return "#f8f8f8";
      default:
        break;
    }
  };

  const readMore = (desc) => {
    document.getElementById("read-more").style.display = "none";
    document.getElementById("shortDesc").style.display = "none";
    document.getElementById("shortDescAlt").style.display = "block";
  };

  const changeCurrentObject = async (property, given_value) => {
    let newShape = shape ? shape : currentProduct.Shape;
    let newMetal = metal ? metal : currentProduct.Metal;
    let newStoneCarat = stoneCarat ? stoneCarat : currentProduct.Stone_Carat;
    let newStoneQuality = stoneQuality
      ? stoneQuality
      : currentProduct.Stone_Quality;
    let newStoneOrigin = stoneOrigin
      ? stoneOrigin
      : currentProduct.Stone_Origin;
    let newCenterStoneCarat = centerStoneCarat
      ? centerStoneCarat
      : currentProduct.Center_Stone_Carat;

    if (property === "shape") {
      newShape = given_value;
      setShape(given_value);
    }

    if (property === "stone-carat") {
      newStoneCarat = given_value;
      setStoneCarat(given_value);
      let carat_circles = document.getElementsByClassName("stone-carat");
      [...carat_circles].forEach((circle) => {
        if (circle.innerText == given_value) {
          circle.classList.add("carat-span-active");
        } else {
          circle.classList.remove("carat-span-active");
        }
      });
    }

    if (property === "metal-carat") {
      newMetal = given_value;
      setMetal(given_value);
      let metal_carat_circles = document.getElementsByClassName("metal-carat");
      [...metal_carat_circles].forEach((circle) => {
        console.log(circle.innerText, "clicked");
        if (circle.innerText === given_value) {
          circle.classList.add("color-circle-2-active");
        } else {
          circle.classList.remove("color-circle-2-active");
        }
      });
    }

    if (property === "stone-quality") {
      newStoneQuality = given_value;
      setStoneQuality(given_value);
      let stone_quality_circles =
        document.getElementsByClassName("stone-quality");
      [...stone_quality_circles].forEach((circle) => {
        if (circle.innerText == given_value) {
          circle.classList.add("custom-btn-active");
        } else {
          circle.classList.remove("custom-btn-active");
        }
      });
    }

    if (property === "stone-origin") {
      newStoneOrigin = given_value;
      setStoneOrigin(given_value);
      let stone_origin_circles =
        document.getElementsByClassName("stone-origin");
      [...stone_origin_circles].forEach((circle) => {
        if (circle.innerText == given_value) {
          circle.classList.add("custom-btn-active");
        } else {
          circle.classList.remove("custom-btn-active");
        }
      });
    }

    if (property === "center-stone-carat") {
      newCenterStoneCarat = given_value;
      setCenterStoneCarat(given_value);
      let center_stone_carat_circles =
        document.getElementsByClassName("center-stone-carat");
      [...center_stone_carat_circles].forEach((circle) => {
        if (circle.innerText == given_value) {
          circle.classList.add("custom-btn-active");
        } else {
          circle.classList.remove("custom-btn-active");
        }
      });
    }

    let formData = new FormData();
    formData.append("method", "get_combination");
    formData.append("action", "getCombinationByParams");
    formData.append("productId", currentProduct.Product_Id);
    formData.append("shape", newShape);
    formData.append("metal", newMetal);
    formData.append("stoneCarat", newStoneCarat);
    formData.append("stoneQuality", newStoneQuality);
    formData.append("stoneOrigin", newStoneOrigin);
    formData.append("centerStoneCarat", newCenterStoneCarat);

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    let combination_video_url = "";
    if (apiResponse.success) {
      setCurrentProduct(apiResponse.result[0]);
      setProductImages(apiResponse.result[0].Product_Images);
      setPrice(apiResponse.result[0].Product_Price);
      combination_video_url = apiResponse.result[0].Combination_Video;
    } else {
      alert("No Combination Found");
    }

    let videoTag = document.createElement("video");
    videoTag.setAttribute("height", "340px");
    videoTag.setAttribute("width", "300px");
    videoTag.setAttribute("id", "prev-video");
    videoTag.setAttribute("autoPlay", true);
    videoTag.classList.add("mx-1");

    let sourceTag = document.createElement("source");
    sourceTag.setAttribute("src", SERVER_URL + combination_video_url);
    sourceTag.setAttribute("type", "video/mp4");

    videoTag.appendChild(sourceTag);
    document.getElementById("prev-video").remove();
    document.getElementById("video-span").appendChild(videoTag);
    setActiveCss(!activeCss);
  };

  const updateProductObject = (property, value) => {
    // console.log(currentProduct, "curr");
    let newProduct = currentProduct;

    if (property === "size") newProduct["Size"] = value;

    if (property === "price") {
      if (value === true) {
        setPrice(currentProduct.Price_Without_Stone);
        currentProduct["Final_Price"] = currentProduct.Price_Without_Stone;
      } else {
        setPrice(currentProduct.Product_Price);
        currentProduct["Final_Price"] = currentProduct.Product_Price;
      }
      //**Add Final Price key here:
      // newProduct['Price'] =
    }

    setCurrentProduct(newProduct);
    console.log(currentProduct, "curr-prod");
    // if (property === "size") currentProduct["Size"] = value;
  };

  const addToCart = (product) => {
    let currentCart = [];
    product["count_in_cart"] = 1;
    product["matchingBandTaken"] = matchingBandTaken; // Add matchingBandTaken to the product
    let total_in_cart = 1;
    if (localStorage.getItem("currentCart")) {
      currentCart = JSON.parse(localStorage.getItem("currentCart"));

      //Check for existing product:
      const existingProduct = currentCart.find(
        (item) => item.Product_Id === product.Product_Id
      );

      if (existingProduct) {
        setSeverity("info");
        setMsg("Product already exist in cart.");
        setShowToast(true);
      } else {
        currentCart.push(product);
        if (localStorage.getItem("total_in_cart")) {
          total_in_cart = parseInt(localStorage.getItem("total_in_cart"));
          total_in_cart += 1;
        }
        localStorage.setItem("total_in_cart", total_in_cart);
        props.setCountInCart(total_in_cart);
        setSeverity("success");
        setMsg("Product added to cart.");
        setShowToast(true);
      }
    } else {
      currentCart.push(product);
      localStorage.setItem("total_in_cart", total_in_cart);
      props.setCountInCart(total_in_cart);
    }
    localStorage.setItem("currentCart", JSON.stringify(currentCart));
  };

  const showRating = () => {
    setRelatedProduct(false);
  };

  const showRelatedProduct = () => {
    setRelatedProduct(true);
  };

  return (
    <>
      <main className="main">
        <div className="container mb-30">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row mt-30">
                  {/* Left Column */}
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="detail-gallery">
                      {/* Map product Images here */}

                      {currentProduct && currentProduct.Cover_Image ? (
                        <img
                          src={SERVER_URL + currentProduct.Cover_Image}
                          alt="product image"
                          className="mx-1"
                          style={{
                            height: "320px",
                            width: "300px",
                          }}
                        />
                      ) : (
                        <img
                          src={"assets/imgs/shop/no-img.jpeg"}
                          alt="product image"
                          className="mx-1"
                          style={{
                            height: "320px",
                            width: "300px",
                          }}
                        />
                      )}

                      {currentProduct && currentProduct.Combination_Video ? (
                        <span id="video-span">
                          <video
                            width="300px"
                            height="340px"
                            autoPlay
                            className="mx-1"
                            id="prev-video"
                          >
                            <source
                              src={
                                SERVER_URL + currentProduct.Combination_Video
                              }
                              type="video/mp4"
                            />
                            Video Unsupported
                          </video>
                        </span>
                      ) : null}

                      {productImages &&
                        productImages.map((imgPath) => (
                          <>
                            <img
                              src={
                                imgPath
                                  ? SERVER_URL + imgPath
                                  : "assets/imgs/shop/no-img.jpeg"
                              }
                              className="mx-1"
                              alt="product image"
                              style={{
                                height: "320px",
                                width: "300px",
                              }}
                            />
                            {/* <ReactImageMagnify
                              style={{
                                width: "300px",
                                height: "320px",
                                marginTop: "10px",
                              }}
                              {...{
                                smallImage: {
                                  alt: "Product Image",
                                  isFluidWidth: true,
                                  src: SERVER_URL + imgPath,
                                },
                                largeImage: {
                                  src: SERVER_URL + imgPath,
                                  width: 1200,
                                  height: 1800,
                                },
                              }}
                            /> */}
                          </>
                        ))}

                      {/* band Images */}

                      {showMatchingBandImage &&
                      currentProduct.Matching_Band_Image ? (
                        <img
                          src={SERVER_URL + currentProduct.Matching_Band_Image}
                          alt="product image"
                          className="mx-1 "
                          style={{
                            minHeight: "320px",
                            width: "300px",
                          }}
                        />
                      ) : null}

                      {/* 3D Hand */}
                      {currentProduct && currentProduct.Hand_Ring ? (
                        <ThreeDHand handRing={currentProduct.Hand_Ring} />
                      ) : null}
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="detail-info pr-30 pl-30">
                      <h4 className="title-detail">
                        {currentProduct && currentProduct.Product_Name}
                      </h4>
                      {/* Product Rating */}
                      <div className="product-detail-rating">
                        <div className="product-rate-cover text-end d-flex align-items-center gap-3">
                          {currentProduct ? (
                            currentProduct.Product_Rating !== "" &&
                            currentProduct.Product_Rating !==
                              "Product Is Not Yet Rated" ? (
                              <>
                                <StarRating
                                  value={currentProduct.Product_Rating}
                                />
                                <span className="font-small text-muted">
                                  {currentProduct.Product_Rating}
                                </span>
                              </>
                            ) : null
                          ) : null}
                        </div>
                      </div>

                      {/* Short Description */}
                      {currentProduct && currentProduct.Short_Description ? (
                        <>
                          <div className="short-desc mb-30">
                            <p className="font-lg">
                              <span id="shortDesc">
                                {currentProduct &&
                                  currentProduct.Short_Description.slice(
                                    0,
                                    85
                                  ) + "...."}
                              </span>
                              <span
                                id="shortDescAlt"
                                style={{ display: "none" }}
                              >
                                {currentProduct &&
                                  currentProduct.Short_Description}
                              </span>
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                className="text-success"
                                id="read-more"
                                onClick={() =>
                                  readMore(
                                    currentProduct
                                      ? currentProduct.Short_Description
                                      : null
                                  )
                                }
                              >
                                Read More
                              </span>
                            </p>
                          </div>
                        </>
                      ) : null}

                      {/* Shape Data and Slider */}
                      {currentProduct && currentProduct.Shape ? (
                        <>
                          <div className="attr-detail attr-size mb-20">
                            <strong>View With Diamond Shape: </strong>
                            <span className="mx-1">
                              {currentProduct.Shape_Name}
                            </span>
                          </div>
                          <div className="shapeImage d-flex">
                            {allShapes &&
                              allShapes.map((shape) => (
                                <div key={shape}>
                                  <Tooltip title={shape.Shape_Name} arrow>
                                    <img
                                      src={SERVER_URL + shape.Shape_Image}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        marginRight: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        changeCurrentObject(
                                          "shape",
                                          shape.Shape
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </>
                      ) : null}
                      {/* {currentProduct && currentProduct.Shape ? (
                        <>
                          <div className="attr-detail attr-size mb-20">
                            <strong>View With Diamond Shape: </strong>
                            <span className="mx-1">
                              {currentProduct.Shape_Name}
                            </span>
                          </div>
                          <div className="attr-detail attr-size mb-20">
                            <div className="swiffy-slider slider-item-show6 slider-nav-dark slider-nav-sm slider-nav-outside slider-nav-visible slider-nav-autohide px-2">
                              <ul className="slider-container">
                                {allShapes &&
                                  allShapes.map((shape) => (
                                    <li key={shape}>
                                      <Tooltip title={shape.Shape_Name} arrow>
                                        <img
                                          src={SERVER_URL + shape.Shape_Image}
                                          style={{
                                            minWidth: "100px",
                                            minHeight: "100px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            changeCurrentObject(
                                              "shape",
                                              shape.Shape
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </li>
                                  ))}
                              </ul>
                              <button type="button" className="slider-nav" />
                              <button
                                type="button"
                                className="slider-nav slider-nav-next"
                              />
                              <div className="slider-indicators">
                                <button className="active" />
                                <button />
                                <button />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null} */}

                      {/* Stone */}
                      {currentProduct && currentProduct.Center_Stone_Carat ? (
                        <>
                          <div className="attr-detail attr-size mb-10">
                            <strong>Center Stone Weight: </strong>
                            <span className="mx-1">
                              {centerStoneCarat && centerStoneCarat} ct. wt.
                            </span>
                          </div>

                          <div className="attr-detail attr-size mb-10 gap-3">
                            {allCenterStoneCarats &&
                              allCenterStoneCarats.map((centerStoneCaratWt) => (
                                <span
                                  className={
                                    centerStoneCarat
                                      ? centerStoneCarat === centerStoneCaratWt
                                        ? "stoneCaratCircle active"
                                        : "stoneCaratCircle"
                                      : "stoneCaratCircle"
                                  }
                                  onClick={() =>
                                    changeCurrentObject(
                                      "center-stone-carat",
                                      centerStoneCaratWt
                                    )
                                  }
                                >
                                  {centerStoneCaratWt}
                                </span>
                              ))}
                          </div>
                        </>
                      ) : null}

                      {/* Metal Data and Circle */}

                      {/* {currentProduct && currentProduct.Metal ? (
                        <>
                          <div className="attr-detail attr-size mb-10">
                            <strong>Metal: </strong>
                            <span className="mx-1">
                              {currentProduct.Metal_Name}
                            </span>
                          </div>
                          {uniqueMetalsGlobal.map((metal, index) => (
                            <Tooltip key={index} title={metal.Metal_Name} arrow>
                              <span
                                // className="color-circle mx-1 p-2"
                                style={{
                                  backgroundColor: metal.Metal_Color,
                                  fontSize: "10px",
                                  cursor: "pointer",
                                  width: "50px",
                                  minHeight: "50px",
                                  padding: "10px",
                                  marginRight: "10px",
                                  borderRadius: "50px",
                                }}
                                onClick={() =>
                                  changeCurrentObject(
                                    "metal-carat",
                                    metal.Metal_Id
                                  )
                                }
                              >
                                {metal.Carat}
                              </span>
                            </Tooltip>
                          ))}
                        </>
                      ) : null} */}

                      {currentProduct && currentProduct.Metal ? (
                        <>
                          <div className="attr-detail attr-size mb-10">
                            <strong>Metal: </strong>
                            <span className="mx-1">
                              {currentProduct.Metal_Name} {currentProduct.Carat}
                            </span>
                          </div>

                          <div className="attr-detail attr-size mb-20">
                            {uniqueMetalsGlobal &&
                              uniqueMetalsGlobal.map((metalObject, index) => (
                                <div key={index}>
                                  <Tooltip title={metalObject.Metal_Name} arrow>
                                    <span
                                      className={
                                        metal
                                          ? metal ===
                                            metalObject.Metal_Name +
                                              "," +
                                              metalObject.Carat
                                            ? "color-circle-2-active metal-carat mx-2"
                                            : "color-circle-2 metal-carat mx-2"
                                          : "color-circle-2 metal-carat mx-2"
                                      }
                                      style={{
                                        color: "Black",
                                        backgroundColor:
                                          metalObject.Metal_Color,
                                        cursor: "pointer",
                                        fontWeight: "bolder",
                                      }}
                                      onClick={() =>
                                        changeCurrentObject(
                                          "metal-carat",
                                          metalObject.Metal_Id
                                        )
                                      }
                                    >
                                      {metalObject.Carat}
                                    </span>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </>
                      ) : null}

                      {/* Stone Carat Data  */}
                      {currentProduct && currentProduct.Stone_Carat ? (
                        <>
                          <div className="attr-detail attr-size">
                            <strong>Total Carat Weight: &nbsp;</strong>
                            <span>{stoneCarat && stoneCarat} ct. wt.</span>
                          </div>
                          <div className="attr-detail attr-size mt-10 gap-3">
                            {allStoneCarat &&
                              allStoneCarat.map((carat) => (
                                <span
                                  className={
                                    stoneCarat
                                      ? stoneCarat === carat
                                        ? "stoneCaratCircle active"
                                        : "stoneCaratCircle"
                                      : "stoneCaratCircle"
                                  }
                                  // className={`stoneCaratCircle ${
                                  // activeCss ? "active" : ""
                                  // }`}
                                  onClick={() =>
                                    changeCurrentObject("stone-carat", carat)
                                  }
                                >
                                  {carat}
                                </span>
                              ))}
                          </div>
                        </>
                      ) : null}

                      {/* Stone Origin */}
                      {currentProduct && currentProduct.Stone_Origin ? (
                        <>
                          <div
                            className="attr-detail attr-size mb-10"
                            style={{ marginTop: "25px" }}
                          >
                            <strong className="mr-10">Diamond Origin: </strong>
                            <span>{stoneOrigin}</span>
                          </div>
                          <div className="attr-detail attr-size mb-10 gap-3">
                            {allOrigins &&
                              allOrigins.map((origin) => (
                                <button
                                  className={
                                    stoneOrigin
                                      ? stoneOrigin === origin
                                        ? "roundBtn active"
                                        : "roundBtn"
                                      : "roundBtn"
                                  }
                                  id="natural"
                                  onClick={() =>
                                    changeCurrentObject("stone-origin", origin)
                                  }
                                >
                                  {origin}
                                </button>
                              ))}
                          </div>
                        </>
                      ) : null}

                      {/* Stone Quality and Buttons */}
                      {currentProduct && currentProduct.Stone_Quality ? (
                        <>
                          <div
                            className="attr-detail attr-size mb-10"
                            style={{ marginTop: "25px" }}
                          >
                            <strong className="mr-10">Diamond Quality: </strong>
                            <span>{stoneQuality}</span>
                          </div>
                          <div className="attr-detail attr-size mb-10 gap-3">
                            {allDiamondQuality &&
                              allDiamondQuality.map((quality) => (
                                <button
                                  className={
                                    stoneQuality
                                      ? stoneQuality === quality
                                        ? "roundBtn active"
                                        : "roundBtn"
                                      : "roundBtn "
                                  }
                                  onClick={() =>
                                    changeCurrentObject(
                                      "stone-quality",
                                      quality
                                    )
                                  }
                                >
                                  {quality}
                                </button>
                              ))}
                          </div>
                        </>
                      ) : null}

                      {/* Price Without cennter Stone */}
                      {currentProduct && currentProduct.Price_Without_Stone ? (
                        <>
                          <div className="attr-detail attr-size mt-4">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id="price-without-checkbox"
                                    onClick={(event) =>
                                      updateProductObject(
                                        "price",
                                        event.target.checked
                                      )
                                    }
                                    size="small"
                                    color="info"
                                  />
                                }
                                label="Price without center stone"
                              />
                            </FormGroup>
                          </div>
                        </>
                      ) : null}

                      {/* Price */}
                      <div className="clearfix product-price-cover">
                        <div className="product-price primary-color float-left">
                          <span
                            className="current-price text-brand"
                            style={{ fontSize: "22px" }}
                          >
                            ${price && price}
                          </span>
                          <span></span>
                        </div>
                      </div>

                      {/* Stone Size Dropdown */}
                      {currentProduct && currentProduct.Size ? (
                        <div className="attr-detail attr-size ">
                          <strong>Ring Size: &nbsp;</strong>
                          <div className="attr-detail attr-size">
                            <FormControl
                              sx={{ m: 1, minWidth: 120 }}
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Size
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                label="Age"
                                onChange={(event) =>
                                  updateProductObject(
                                    "size",
                                    event.target.value
                                  )
                                }
                              >
                                {/* {sizes &&
                                  sizes.map((size) => (
                                    <MenuItem value={size}>{size}</MenuItem>
                                  ))} */}
                                {currentProduct.Size.map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ) : null}

                      {/* Add Engraving */}
                      {currentProduct && currentProduct.Engraving_Available ? (
                        <>
                          <div className="addEngraving">
                            <button
                              onClick={showEngravingFunc}
                              className="button my-3"
                            >
                              + Add Engraving
                            </button>
                            {showEngraving && (
                              <input
                                type="text"
                                placeholder="Enter Your Engraving Text Of Upto 20 Characters"
                                maxlength="20"
                                className="mx-2"
                              />
                            )}
                          </div>
                        </>
                      ) : null}

                      {/* Product Weight */}
                      {currentProduct && currentProduct.Product_Weight ? (
                        <>
                          <div
                            className="attr-detail attr-size mb-50"
                            style={{ marginTop: "25px" }}
                          >
                            <strong className="mr-10">
                              Weight:{" "}
                              <span className="text-brand">
                                {currentProduct && productWeight}
                              </span>
                            </strong>
                          </div>
                          <div className="detail-extralink mb-50"></div>
                        </>
                      ) : null}

                      {/* Matching Band kindly add necessary condition for rendering at curent product */}
                      {currentProduct && currentProduct.Matching_Band_Name ? (
                        <>
                          <div>
                            <div className="attr-detail attr-size ">
                              <strong>Show Matching Band</strong>
                            </div>
                            <Switch
                              onClick={showMatchingBandFunc}
                              checked={isChecked}
                              onChange={handleToggleChange}
                              inputProps={{ "aria-label": "Toggle switch" }}
                            />
                            {showMatchingBand && (
                              <Row className="matchingband">
                                <Col
                                  xs={3}
                                  className="d-flex justify-content-center p-0 m-0"
                                >
                                  <Image
                                    style={{ width: "100px", height: "100px" }}
                                    src={
                                      currentProduct
                                        ? SERVER_URL +
                                          currentProduct.Matching_Band_Image
                                        : "assets/imgs/shop/no-img.jpeg"
                                    }
                                  />
                                </Col>
                                <Col
                                  xs={9}
                                  className="d-flex flex-column justify-content-start"
                                >
                                  <h6 className="title-detail">
                                    {currentProduct &&
                                      currentProduct.Matching_Band_Name}
                                  </h6>
                                  <div className="d-flex align-items-center pt-3">
                                    <h6>Product Price : &nbsp;</h6>
                                    <span
                                      className="current-price text-brand"
                                      style={{
                                        paddingBottom: "3px",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      $
                                      {currentProduct &&
                                        currentProduct.Matching_Band_Price}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </div>
                        </>
                      ) : null}

                      {/* add to bag  */}
                      <div className="product-extra-link2 my-3">
                        <button
                          type="submit"
                          className="button button-add-to-cart"
                          onClick={() => addToCart(currentProduct, true)}
                        >
                          <i className="fi-rs-shopping-cart" />
                          Add to Bag
                        </button>
                      </div>

                      {/* product information */}
                      {currentProduct && currentProduct.Product_Information ? (
                        <>
                          <h4 style={{ marginTop: "50px" }} className="mb-3">
                            Product Details:
                          </h4>
                          {productInformation &&
                            Object.keys(productInformation).map((key) => (
                              <div className="row mb-2">
                                <div className="col-5">
                                  <strong>{key}</strong>:
                                </div>
                                <div className="col-7">
                                  <span className="mx-1">
                                    {productInformation[key]}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </>
                      ) : null}

                      {/* Product Measurements */}
                      {currentProduct && currentProduct.Product_Measurement ? (
                        <>
                          <h4 style={{ marginTop: "50px" }} className="mb-3">
                            Product Measurements:
                          </h4>
                          {productMeasurement &&
                            Object.keys(productMeasurement).map((key) => (
                              <div className="row mb-2">
                                <div className="col-5">
                                  <strong>{key}</strong>:
                                </div>
                                <div className="col-7">
                                  <span className="mx-1">
                                    {productMeasurement[key]}
                                  </span>
                                </div>
                              </div>
                            ))}

                          <img
                            className="my-3"
                            src={
                              currentProduct
                                ? currentProduct.Product_Measurement_Image
                                : "assets/imgs/shop/no-img.jpeg"
                            }
                            alt="Measurement Image"
                            style={{
                              height: "130px",
                              width: "100%",
                            }}
                          />

                          <p style={{ fontSize: "14px" }} className="my-3">
                            * This diagram image is for measurement purposes
                            only and does not represent the actual item shown.
                          </p>

                          <p style={{ fontSize: "14px" }} className="mt-3">
                            * Product weights and dimensions are approximate.
                            Our total diamond weight may have a +/-5% tolerance.
                            All prices on this web site are subject to change
                            without notice. Whilst we make every effort to
                            provide you the most accurate, up-to-date
                            information, occasionally, one or more items on our
                            web site may be mis-priced. In the event a product
                            is listed at an incorrect price due to
                            typographical, photographic, or technical error.
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Related Products */}
                <div className="d-flex mx-3 mt-3 flex-column">
                  <div className="header d-flex gap-3">
                    <span
                      className="relatedProductButton"
                      style={{
                        cursor: "pointer",
                        border: "1px solid #ececec",
                        padding: "13px 24px",
                        borderRadius: "30px",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "17px",
                        fontWeight: "700",
                        background: "#FFF",
                        color: "#7E7E7E",
                        textAlign: "center",
                        "&:hover": {
                          color: "#3BB77E",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.05)",
                        },
                      }}
                      onClick={showRelatedProduct}
                    >
                      Related Products
                    </span>
                    <span
                      className="ratingButton"
                      style={{
                        cursor: "pointer",
                        border: "1px solid #ececec",
                        padding: "13px 24px",
                        borderRadius: "30px",
                        fontFamily: "Quicksand, sans-serif",
                        fontSize: "17px",
                        fontWeight: "700",
                        background: "#FFF",
                        color: "#7E7E7E",
                        textAlign: "center",
                        "&:hover": {
                          color: "#3BB77E",
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.05)",
                        },
                      }}
                      onClick={showRating}
                    >
                      Ratings and Review
                    </span>
                  </div>
                  <div className="content d-flex align-items-center justify-content-center gap-5 mt-30">
                    {relatedProducts ? (
                      <>
                        {relatedProductData &&
                          relatedProductData.map((product, index) => (
                            <div className="col-md-4 col-12 col-sm-6">
                              <div className="product-cart-wrap mb-30">
                                <div className="product-img-action-wrap">
                                  <div className="product-img product-img-zoom">
                                    <Link>
                                      <>
                                        <img
                                          className="default-img first-default-img"
                                          src={SERVER_URL + product.Cover_Image}
                                          alt
                                        />
                                        <img
                                          className="hover-img"
                                          // src={SERVER_URL + metal.Cover_Image}
                                          src={"assets/imgs/shop/no-img.jpeg"}
                                          alt="Cover Image"
                                        />
                                      </>
                                    </Link>
                                  </div>

                                  {/* {currentProduct.Product_Sale_Price !==
                            currentProduct.Product_Price ? ( */}
                                  <div className="product-badges product-badges-position product-badges-mrg">
                                    <span className="sale">Sale</span>
                                  </div>
                                  {/* ) : null} */}
                                </div>

                                <div className="product-content-wrap">
                                  <div className="product-category">
                                    {product.Category_Name}
                                  </div>
                                  <Link>
                                    <h2>{product.Product_Name}</h2>
                                  </Link>

                                  <div className="shopMetal">
                                    <span
                                      // key={index}
                                      style={{
                                        backgroundColor: product.Metal_Color,
                                        fontSize: "10px",
                                        cursor: "pointer",
                                        padding: "10px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      {product.Carat}
                                    </span>
                                  </div>

                                  <div className="product-card-bottom">
                                    <div className="product-price">
                                      <span>$ {product.Product_Price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      <div className="reviewRatings row gap-3 mt-3">
                        <div className="Ratings d-flex flex-column col-xs-12 col-lg-7">
                          <h4 className="mb-3" style={{ color: "#B6B6B6 " }}>
                            Customer Reviews
                          </h4>
                          <Card className="ratingCard">
                            <Card.Body
                              className="d-flex gap-2"
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              <div className="col-3 d-flex flex-column align-items-center">
                                <img
                                  src="https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                                  alt="user Image"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <h6 style={{ color: "#3BB77E " }}>
                                  Vinayak Thakur
                                </h6>
                              </div>
                              <div className="col-9 d-flex flex-column">
                                <div
                                  className="dateRating d-flex justify-content-between"
                                  style={{ color: "#B6B6B6 " }}
                                >
                                  <Card.Text className="date">
                                    27 Decmber 2023
                                  </Card.Text>
                                  <Card.Text className="Rating">
                                    <StarRating
                                      value={currentProduct.Product_Rating}
                                    />
                                  </Card.Text>
                                </div>
                                <Card.Text className="review">
                                  With supporting text below as a natural
                                  lead-in to additional content.With supporting
                                  text below as a natural lead-in to additional
                                  content.With supporting text below as a
                                  natural lead-in to additional content.
                                </Card.Text>
                                <div className="d-flex">
                                  <img
                                    src="https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                                    alt=""
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      marginTop: "20px",
                                    }}
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="Review d-flex flex-column col-xs-12 col-lg-4">
                          <h4 className="mb-3" style={{ color: "#B6B6B6 " }}>
                            Customer Ratings
                          </h4>
                          <span className="d-flex justify-content-between mb-3">
                            <StarRating value={currentProduct.Product_Rating} />
                            {/* <StarRating value={currentProduct.Product_Rating} /> */}
                            <span className="font-small text-muted">
                              4.5 out of 5
                              {/* {currentProduct.Product_Rating} */}
                            </span>
                          </span>
                          <div className="RatingRows d-flex flex-column gap-3">
                            <div className="ratingEachRow d-flex gap-5">
                              <h6>5 Star</h6>
                              <div
                                style={{
                                  backgroundColor: "#e9ecef",
                                  width: "150px",
                                  height: "20px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 5px",
                                    backgroundColor: "#3BB77E",
                                    width: "50%",
                                    height: "20px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  50%
                                </div>
                              </div>
                            </div>
                            <div className="ratingEachRow d-flex gap-5">
                              <h6>4 Star</h6>
                              <div
                                style={{
                                  backgroundColor: "#e9ecef",
                                  width: "150px",
                                  height: "20px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 5px",
                                    backgroundColor: "#3BB77E",
                                    width: "50%",
                                    height: "20px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  50%
                                </div>
                              </div>
                            </div>
                            <div className="ratingEachRow d-flex gap-5">
                              <h6>3 Star</h6>
                              <div
                                style={{
                                  backgroundColor: "#e9ecef",
                                  width: "150px",
                                  height: "20px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 5px",
                                    backgroundColor: "#3BB77E",
                                    width: "50%",
                                    height: "20px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  50%
                                </div>
                              </div>
                            </div>
                            <div className="ratingEachRow d-flex gap-5">
                              <h6>2 Star</h6>
                              <div
                                style={{
                                  backgroundColor: "#e9ecef",
                                  width: "150px",
                                  height: "20px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 5px",
                                    backgroundColor: "#3BB77E",
                                    width: "50%",
                                    height: "20px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  50%
                                </div>
                              </div>
                            </div>
                            <div className="ratingEachRow d-flex gap-5">
                              <h6>1 Star</h6>
                              <div
                                style={{
                                  backgroundColor: "#e9ecef",
                                  width: "150px",
                                  height: "20px",
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 5px",
                                    backgroundColor: "#3BB77E",
                                    width: "50%",
                                    height: "20px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  50%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {showToast && (
        <ToastMsg
          showToast={showToast}
          setShowToast={setShowToast}
          severity={severity}
          msg={msg}
        />
      )}
      <Footer />
    </>
  );
}
export default Product;
