import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";

const Users = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadUsers = () => {
    fetch("http://visionelectricalsystems.com/api.php?method=get_all_users")
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setRows(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const columns = [
    {
      field: "userId",
      headerName: "User Id",
      width: 100,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email Id",
      width: 300,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 200,
    },
  ];

  return (
    <>
      <Card className="mt-3">
        <h5 className="mx-3 my-3">Users</h5>
        <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
        <CardContent style={{ display: "flex" }}>
          {!loading ? (
            <DataGrid
              rows={rows.map((row) => ({
                id: row.Id,
                userId: row.User_Id,
                userName: row.User_Name,
                email: row.Email,
                mobile: row.Mobile,
              }))} // Assuming "Id" is the unique identifier
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 100]}
              disableRowSelectionOnClick
            />
          ) : (
            <CircularProgress />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Users;
