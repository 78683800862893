import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const ShopByCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // const history = useHistory();
  const { categoryData } = location.state || {};
  const baseUrl = "http://visionelectricalsystems.com/";
  const fullImageUrl = baseUrl + categoryData.Category_Banner;

  const [loading, setLoading] = useState(true);
  const [shapeData, setShapeData] = useState([]);
  const [styleData, setStyleData] = useState([]);

  let fromCategory = false;

  const getShapesByCatId = () => {
    const formData = new FormData();
    formData.append("categoryId", categoryData.Category_Id);

    fetch(
      "http://visionelectricalsystems.com/api.php?method=shape&&action=getByCategoryId",
      { method: "POST", body: formData }
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setShapeData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const getStyleByCatId = () => {
    const formData = new FormData();
    formData.append("categoryId", categoryData.Category_Id);

    fetch(
      "http://visionelectricalsystems.com/api.php?method=style&&action=getByCategoryId",
      { method: "POST", body: formData }
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setStyleData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getShapesByCatId();
    getStyleByCatId();
  }, []);

  const handleRedirectToShopForStyle = (category, style) => {
    fromCategory = true;
    navigate(
      "/shop?fromCategory=" +
        fromCategory +
        "&fromStyle=true" +
        "&category=" +
        category +
        "&style=" +
        style
    );
  };
  const handleRedirectToShopForShape = (category, shape) => {
    fromCategory = true;
    // Add category and shape as query parameters when redirecting to Shop
    navigate(
      "/shop?fromCategory=" +
        fromCategory +
        "&fromShape=true" +
        "&category=" +
        category +
        "&shape=" +
        shape
    );
  };

  return (
    <>
      <div className="banner">
        <Image
          className="bannerImage"
          src={fullImageUrl}
          alt={categoryData.Category_Name}
        />
        {/* {categoryData.Category_Name} */}
      </div>

      {!loading ? (
        <>
          {styleData && styleData.length > 0 ? (
            <>
              <div className="d-flex flex-column justify-content-center mt-5">
                <h4 className="title d-flex justify-content-center">
                  Shop {categoryData.Category_Name}&nbsp;
                  <span style={{ color: "red" }}>By Style</span>
                </h4>
                <div className="shapeImages d-flex justify-content-around align-items-end pt-25">
                  {styleData.slice(0, 4).map((style) => (
                    <div
                      className="d-flex flex-column align-items-center gap-3"
                      style={{ cursor: "pointer" }}
                      key={style.Style_Id}
                      onClick={() =>
                        handleRedirectToShopForStyle(
                          categoryData.Category_Id,
                          style.Style_Id
                        )
                      }
                    >
                      {/* You should use the actual image URL from the API response */}
                      <Image
                        style={{ width: "200px", height: "200px" }}
                        src={baseUrl + style.Style_Image}
                        alt={style.Style_Name}
                      />
                      <Typography>{style.Style_Name}</Typography>
                    </div>
                  ))}
                </div>
                <div className="viewAllBtn align-self-center pt-50">
                  <Link to="/shop">
                    <Button variant="outline-success">VIEW ALL</Button>
                  </Link>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <CircularProgress />
      )}

      {!loading ? (
        <>
          {shapeData && shapeData.length > 0 ? (
            <>
              <div className="d-flex flex-column justify-content-center my-5">
                <h4 className="title d-flex justify-content-center">
                  Shop {categoryData.Category_Name}&nbsp;
                  <span style={{ color: "red" }}>By Shape</span>
                </h4>
                <div className="shapeImages d-flex justify-content-around align-items-end pt-25">
                  {shapeData.slice(0, 4).map((shape) => (
                    <div
                      className="d-flex flex-column align-items-center gap-3"
                      style={{ cursor: "pointer" }}
                      key={shape.Shape_Id}
                      onClick={() =>
                        handleRedirectToShopForShape(
                          categoryData.Category_Id,
                          shape.Shape_Id
                        )
                      }
                    >
                      {/* You should use the actual image URL from the API response */}
                      <Image
                        style={{ width: "200px", height: "200px" }}
                        src={baseUrl + shape.Shape_Image}
                        alt={shape.Shape_Name}
                      />
                      <Typography>{shape.Shape_Name}</Typography>
                    </div>
                  ))}
                </div>
                <div className="viewAllBtn align-self-center pt-50">
                  <Link to="/shop">
                    <Button variant="outline-success">VIEW ALL</Button>
                  </Link>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <CircularProgress />
      )}

      {/* <div className="d-flex flex-column justify-content-center mt-5">
        <h4 className="title d-flex justify-content-center">
          Shop {categoryData.Category_Name}&nbsp;
          <span style={{ color: "red" }}>By Style</span>
        </h4>
        <div className="shapeImages d-flex justify-content-around align-items-end pt-25">
          <Link to="/shop">
            <Image
              style={{ width: "180px", height: "180px" }}
              src={require("../../Asset/Shape/asscher.jpg")}
              alt="shape"
            />
          </Link>
          <Link to="/shop">
            <Image
              style={{ width: "150px", height: "150px" }}
              src={require("../../Asset/Shape/princess.jpg")}
              alt="shape"
            />
          </Link>
          <Link to="/shop">
            <Image
              style={{ width: "150px", height: "150px" }}
              src={require("../../Asset/Shape/heart.jpg")}
              alt="shape"
            />
          </Link>
          <Link to="/shop">
            <Image
              style={{ width: "150px", height: "150px" }}
              src={require("../../Asset/Shape/trilliant.jpg")}
              alt="shape"
            />
          </Link>
          <Link to="/shop">
            <Image
              style={{ width: "150px", height: "150px" }}
              src={require("../../Asset/Shape/radiant.jpg")}
              alt="shape"
            />
          </Link>
        </div>
        <div className="viewAllBtn align-self-center pt-50">
          <Link to="/shop">
            <Button variant="outline-success">VIEW ALL</Button>
          </Link>
        </div>
      </div> */}
    </>
  );
};

export default ShopByCategory;
