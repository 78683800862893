import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Rating from "@mui/material/Rating";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { DataGrid } from "@mui/x-data-grid";
import { SERVER_URL, api } from "../../apiCall";
import { Box, FormGroup } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// import Grid from "@mui/material/Grid"; // Grid version 1
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const AddProduct = () => {
  const [open, setOpen] = React.useState(true);
  const [allCategories, setAllCategories] = useState(null);
  const [productId, setProductId] = useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [isIdFilled, setIsIdFilled] = useState(true);
  const [isCategoryFilled, setIsCategoryFilled] = useState(true);
  const [productRating, setProductRating] = useState(3.5);
  const [combinationCount, setCombinationCount] = useState(1);

  const [openScrollPaper, setOpenScrollPaper] = React.useState(false);
  const [scrollModalTitle, setScrollModalTitle] = useState(null);
  const [scrollModalColumns, setScrollModalColumns] = useState(null);
  const [scrollModalRows, setScrollModalRows] = useState(null);
  const [modalOpenFor, setModalOpenFor] = useState(null);
  const [allShapes, setAllShapes] = useState(null);
  const [allMetals, setAllMetals] = useState(null);
  const [selectedShapes, setSelectedShapes] = useState(null);
  const [selectedMetals, setSelectedMetals] = useState(null);
  const [addedCenterStoneCarats, setAddedCenterStoneCarats] = useState([]);
  const [addedStoneCarats, setAddedStoneCarats] = useState([]);
  const [addedStoneQuality, setAddedStoneQuality] = useState([]);
  const [centerStoneInputCount, setcenterStoneInputCount] = useState(0);
  const [stoneInputCount, setstoneInputCount] = useState(0);
  const [stoneQualityInputCount, setStoneQualityInputCount] = useState(0);
  const [isShapeAvl, setIsShapeAvl] = useState(true);
  const [isMetalAvl, setIsMetalAvl] = useState(false);
  const [isStoneCaratAvl, setIsStoneCaratAvl] = useState(false);
  const [isCenterStoneCaratAvl, setIsCenterStoneCaratAvl] = useState(false);
  const [isStoneOriginAvl, setIsStoneOriginAvl] = useState(false);
  const [isStoneQualityAvl, setIsStoneQualityAvl] = useState(false);
  const [stoneOriginCount, setStoneOriginCount] = useState(0);
  const [dataGridColumns, setDataGridColumns] = useState([
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "productId",
      headerName: "Product ID",
      width: 150,
      editable: true,
    },
    // {
    //   field: "skuid",
    //   headerName: "SKU ID",
    //   width: 150,
    //   editable: true,
    // },
    {
      field: "combinationId",
      headerName: "Combination ID",
      width: 110,
      editable: true,
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 200,
      editable: true,
    },
    {
      field: "shape",
      headerName: "Shape",
      editable: true,
      width: 150,
    },
    {
      field: "style",
      headerName: "Product Style",
      editable: true,
      width: 160,
    },
    {
      field: "size",
      headerName: "Size",
      editable: true,
      width: 150,
    },
    {
      field: "coverImage",
      headerName: "Cover Image",
      editable: true,
      width: 150,
    },
    {
      field: "combinationVideo",
      headerName: "Combination Video",
      editable: true,
      width: 150,
    },
    {
      field: "productPrice",
      headerName: "Product Price",
      editable: true,
      width: 150,
    },
    {
      field: "productPriceWithoutStone",
      headerName: "Product Price Without Stone",
      editable: true,
      width: 200,
    },
    {
      field: "shortDescription",
      headerName: "Short Description",
      editable: true,
      width: 150,
    },
    {
      field: "productInformation",
      headerName: "Product Information",
      editable: true,
      width: 150,
    },
    {
      field: "productImages",
      headerName: "Product Images",
      editable: true,
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={() => addCombination(params.row)}
        >
          Upload
        </Button>
      ),
    },
  ]);

  const [dataGridRows, setDataGridRows] = useState([]);

  // const theme = useTheme();

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    // alert(stoneOriginCount);
    const selectedMetalsLength = selectedMetals ? selectedMetals.length : 0;
    const selectedShapesLength = selectedShapes ? selectedShapes.length : 0;
    const addedCenterStoneCaratsLength = addedCenterStoneCarats
      ? addedCenterStoneCarats.length
      : 0;
    const addedStoneCaratsLength = addedStoneCarats
      ? addedStoneCarats.length
      : 0;
    const addedStoneQualityLength = addedStoneQuality
      ? addedStoneQuality.length
      : 0;
    const stoneOriginCounts = stoneOriginCount;

    const lengths = [
      selectedMetalsLength,
      selectedShapesLength,
      addedCenterStoneCaratsLength,
      addedStoneCaratsLength,
      stoneOriginCounts,
      addedStoneQualityLength,
    ];

    // Filter out lengths that are zero, then multiply the remaining lengths
    const result = lengths
      .filter((length) => length > 0)
      .reduce((product, length) => product * length, 1);

    setCombinationCount(result);

    // Create a new array with the required number of rows
    const newRows = Array.from({ length: result }, (_, index) => ({
      id: index + 1,
    }));

    // Update the state with the new rows
    setDataGridRows(newRows);
  }, [
    selectedMetals,
    selectedShapes,
    addedCenterStoneCarats,
    addedStoneCarats,
    stoneOriginCount,
    addedStoneQuality,
  ]);

  const loadCategories = async () => {
    let apiResponse = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=category&&action=getAll"
    );

    setAllCategories(apiResponse.result);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    if (productId && productCategory && productRating) {
      let formData = new FormData();

      formData.append("method", "product");
      formData.append("action", "insertProduct");
      formData.append("productId", productId);
      formData.append("categoryName", productCategory);
      formData.append("productRating", productRating);

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status === true) setOpen(false);
      else alert("An Error Occured!");
    } else {
      if (!productId) setIsIdFilled(false);
      else setIsIdFilled(true);

      if (!productCategory) setIsCategoryFilled(false);
      else setIsCategoryFilled(true);
    }
  };

  const setInitialFields = (fieldName, value) => {
    switch (fieldName) {
      case "product-id":
        setProductId(value);
        break;
      case "product-category":
        setProductCategory(value);
        break;
      case "product-rating":
        setProductRating(value);
        break;
      default:
        break;
    }
  };

  const toggleScrollModal = (property) => {
    if (property !== "cancel") {
      setScrollModalData(property);
      setOpenScrollPaper(!openScrollPaper);

      let allCenterStoneCaratInputs = document.getElementsByClassName(
        "center-stone-carat-input"
      );

      let allStoneCaratInputs =
        document.getElementsByClassName("stone-carat-input");

      let allStoneQualityInputs = document.getElementsByClassName(
        "stone-quality-input"
      );

      if (allCenterStoneCaratInputs.length > 0) {
        let newValues = Array.from(allCenterStoneCaratInputs)
          .map((input) => input.value)
          .filter((value) => value.trim() !== ""); // Exclude empty values

        if (newValues.length > 0) {
          setAddedCenterStoneCarats((prevValues) => [
            ...prevValues,
            ...newValues,
          ]);
        }
        setcenterStoneInputCount(0);
      }

      if (allStoneQualityInputs.length > 0) {
        let newValues = Array.from(allStoneQualityInputs)
          .map((input) => input.value)
          .filter((value) => value.trim() !== ""); // Exclude empty values

        if (newValues.length > 0) {
          setAddedStoneQuality((prevValues) => [...prevValues, ...newValues]);
        }
        setStoneQualityInputCount(0);
      }

      if (allStoneCaratInputs.length > 0) {
        let newValues = Array.from(allStoneCaratInputs)
          .map((input) => input.value)
          .filter((value) => value.trim() !== ""); // Exclude empty values

        if (newValues.length > 0) {
          setAddedStoneCarats((prevValues) => [...prevValues, ...newValues]);
        }
        setstoneInputCount(0);
      }
    } else {
      setOpenScrollPaper(!openScrollPaper);
      setcenterStoneInputCount(0);
      setstoneInputCount(0);
      setStoneQualityInputCount(0);
    }
  };

  const setScrollModalData = (property) => {
    switch (property) {
      case "shape":
        setScrollModalTitle("Choose Shape");
        setModalOpenFor("shape");
        setScrollModalTableData(property);
        break;
      case "metal":
        setScrollModalTitle("Choose Metal");
        setModalOpenFor("metal");
        setScrollModalTableData(property);
        break;
      case "center-stone-carat":
        setScrollModalTitle("Add Center Stone Carat");
        setModalOpenFor("center-stone-carat");
        break;
      case "stone-carat":
        setScrollModalTitle("Add Stone Carat");
        setModalOpenFor("stone-carat");
        break;
      case "stoneQuality":
        setScrollModalTitle("Add Stone Quality");
        setModalOpenFor("stone-quality");
        break;

      default:
        break;
    }
  };

  const setScrollModalTableData = async (property) => {
    let apiResponse = await api(
      "GET",
      "http://visionelectricalsystems.com/api.php?method=" +
        property +
        "&action=getAll"
    );

    console.log("apiresp", apiResponse);

    if (property === "shape") {
      let shapesRows = [];
      setScrollModalColumns([
        { field: "id", headerName: "ID", width: 50 },
        { field: "shape", headerName: "Shape", width: 70 },
        {
          field: "shapeImage",
          headerName: "Shape Image",
          width: 100,
          renderCell: (params) => <img height={"50px"} src={params.value} />,
        },
      ]);

      apiResponse.result.forEach((shapeData) => {
        let newShapeObj = {
          id: shapeData.Id,
          shape: shapeData.Shape_Name,
          shapeImage: SERVER_URL + shapeData.Shape_Image,
        };

        shapesRows.push(newShapeObj);
      });

      setScrollModalRows(shapesRows);
    } else if (property === "metal") {
      let metalRows = [];

      setScrollModalColumns([
        { field: "id", headerName: "ID", width: 50 },
        { field: "metal", headerName: "Metal", width: 100 },
        { field: "metalColor", headerName: "Metal Color", width: 100 },
        { field: "metalCarat", headerName: "Metal Carat", width: 100 },
      ]);

      apiResponse.result.forEach((metalData) => {
        let newMetalObject = {
          id: metalData.Id,
          metal: metalData.Metal_Name,
          metalColor: metalData.Metal_Color,
          metalCarat: metalData.Carat,
        };

        metalRows.push(newMetalObject);
      });

      setScrollModalRows(metalRows);
    }
  };

  const getSelectedRows = (selectedRowData) => {
    if (modalOpenFor === "shape") setSelectedShapes(selectedRowData);
    if (modalOpenFor === "metal") setSelectedMetals(selectedRowData);
  };

  const handleAddInput = () => {
    // Increment the input count when the "Add New" button is clicked
    if (modalOpenFor === "center-stone-carat")
      setcenterStoneInputCount(centerStoneInputCount + 1);

    if (modalOpenFor === "stone-carat") setstoneInputCount(stoneInputCount + 1);

    if (modalOpenFor === "stone-quality")
      setStoneQualityInputCount(stoneQualityInputCount + 1);
  };

  const toggleInfoAndColumn = (property, event) => {
    let field = "";
    let headerName = "";
    let indexToInsertOn = "";

    switch (property) {
      case "shape":
        field = "shape";
        headerName = "Shape";
        indexToInsertOn = 4;
        if (event.target.checked) setIsShapeAvl(true);
        else setIsShapeAvl(false);
        break;
      case "metal":
        field = "metal";
        headerName = "Metal";
        indexToInsertOn = 5;
        if (event.target.checked) setIsMetalAvl(true);
        else setIsMetalAvl(false);
        break;
      case "stoneCarat":
        field = "stoneCarat";
        headerName = "Stone Carat";
        indexToInsertOn = 3;
        if (event.target.checked) setIsStoneCaratAvl(true);
        else setIsStoneCaratAvl(false);
        break;
      case "centerStoneCarat":
        field = "centerStoneCarat";
        headerName = "Center Stone Carat";
        indexToInsertOn = 4;
        if (event.target.checked) setIsCenterStoneCaratAvl(true);
        else setIsCenterStoneCaratAvl(false);
        break;
      case "matchingBand":
        if (event.target.checked) {
          const newColumn1 = {
            field: "matchingBandName",
            headerName: "Matching Band Name",
            editable: true,
            width: 150,
          };
          setDataGridColumns((prevColumns) => [
            ...prevColumns.slice(0, 11),
            newColumn1,
            ...prevColumns.slice(11),
          ]);

          const newColumn2 = {
            field: "matchingBandImage",
            headerName: "Matching Band Image",
            editable: true,
            width: 150,
          };
          setDataGridColumns((prevColumns) => [
            ...prevColumns.slice(0, 12),
            newColumn2,
            ...prevColumns.slice(12),
          ]);

          const newColumn3 = {
            field: "matchingBandPrice",
            headerName: "Matching Band Price",
            editable: true,
            width: 150,
          };
          setDataGridColumns((prevColumns) => [
            ...prevColumns.slice(0, 13),
            newColumn3,
            ...prevColumns.slice(13),
          ]);
        } else {
          setDataGridColumns((prevColumns) =>
            prevColumns.filter((column) => column.field !== "matchingBandName")
          );
          setDataGridColumns((prevColumns) =>
            prevColumns.filter((column) => column.field !== "matchingBandImage")
          );
          setDataGridColumns((prevColumns) =>
            prevColumns.filter((column) => column.field !== "matchingBandPrice")
          );
        }
        break;
      case "measurement":
        if (event.target.checked) {
          const newColumn1 = {
            field: "productMeasurement",
            headerName: "Product Measurement",
            editable: true,
            width: 180,
          };
          setDataGridColumns((prevColumns) => [
            ...prevColumns.slice(0, 11),
            newColumn1,
            ...prevColumns.slice(11),
          ]);

          const newColumn2 = {
            field: "productMeasurementImage",
            headerName: "Product Measurement Image",
            editable: true,
            width: 180,
          };
          setDataGridColumns((prevColumns) => [
            ...prevColumns.slice(0, 12),
            newColumn2,
            ...prevColumns.slice(12),
          ]);
        } else {
          setDataGridColumns((prevColumns) =>
            prevColumns.filter(
              (column) => column.field !== "productMeasurement"
            )
          );
          setDataGridColumns((prevColumns) =>
            prevColumns.filter(
              (column) => column.field !== "productMeasurementImage"
            )
          );
        }
        break;
      case "stoneOrigin":
        field = "stoneOrigin";
        headerName = "Stone Origin";
        indexToInsertOn = 6;
        if (event.target.checked) setIsStoneOriginAvl(true);
        else setIsStoneOriginAvl(false);
        break;
      case "stoneQuality":
        field = "stoneQuality";
        headerName = "Stone Quality";
        indexToInsertOn = 7;
        if (event.target.checked) setIsStoneQualityAvl(true);
        else setIsStoneQualityAvl(false);
        break;
      case "isActive":
        if (event.target.checked)
          document.getElementById("is-active").value = 1;
        else document.getElementById("is-active").value = 0;
      default:
        break;
    }

    if (property !== "matchingBand" && property !== "measurement") {
      if (event.target.checked) {
        const newColumn = {
          field: field,
          headerName: headerName,
          width: 150,
          editable: true,
        };
        setDataGridColumns((prevColumns) => [
          ...prevColumns.slice(0, indexToInsertOn),
          newColumn,
          ...prevColumns.slice(indexToInsertOn),
        ]);
      } else {
        setDataGridColumns((prevColumns) =>
          prevColumns.filter((column) => column.field !== field)
        );
      }
    }
  };

  const toggleOriginButtons = (btnName) => {
    if (btnName === "natural-outlined") {
      document.getElementById("natural-outlined").style.display = "none";
      document.getElementById("natural-contained").style.display =
        "inline-block";
      setStoneOriginCount(stoneOriginCount + 1);
    }
    if (btnName === "natural-contained") {
      document.getElementById("natural-contained").style.display = "none";
      document.getElementById("natural-outlined").style.display =
        "inline-block";
      if (stoneOriginCount !== 0) setStoneOriginCount(stoneOriginCount - 1);
    }
    if (btnName === "lab-grown-outlined") {
      document.getElementById("lab-grown-outlined").style.display = "none";
      document.getElementById("lab-grown-contained").style.display =
        "inline-block";
      setStoneOriginCount(stoneOriginCount + 1);
    }
    if (btnName === "lab-grown-contained") {
      document.getElementById("lab-grown-contained").style.display = "none";
      document.getElementById("lab-grown-outlined").style.display =
        "inline-block";
      if (stoneOriginCount !== 0) setStoneOriginCount(stoneOriginCount - 1);
    }
  };

  const addCombination = async (rowData, event) => {
    if (
      rowData.productId &&
      rowData.combinationId &&
      rowData.productName &&
      rowData.productPrice
    ) {
      console.log(rowData.productId, "rowData");

      let formData = new FormData();

      formData.append("method", "product");
      formData.append("action", "insertCombination");

      let productId = rowData.productId;
      formData.append("productId", productId);

      let skuId = document.getElementById("sku-id").value;
      formData.append("skuId", skuId);

      let combinationId = rowData.combinationId;
      formData.append("combinationId", combinationId);

      let productName = rowData.productName;
      formData.append("productName", productName);

      let stoneQuality = rowData.stoneQuality;
      formData.append("stoneQuality", stoneQuality);

      let centerStoneCarat = rowData.centerStoneCarat;
      formData.append("centerStoneCarat", centerStoneCarat);

      let stoneCarat = rowData.stoneCarat;
      formData.append("stoneCarat", stoneCarat);

      let shape = rowData.shape;
      formData.append("shape", shape);

      let metal = rowData.metal;
      formData.append("metal", metal);

      let stoneOrigin = rowData.stoneOrigin;
      formData.append("stoneOrigin", stoneOrigin);

      let size = rowData.size;
      formData.append("size", size);

      let matchingBandName = rowData.matchingBandName;
      formData.append("matchingBandName", matchingBandName);

      let matchingBandPrice = rowData.matchingBandPrice;
      formData.append("matchingBandPrice", matchingBandPrice);

      let matchingBandImage = rowData.matchingBandImage;
      formData.append("matchingBandImage", matchingBandImage);

      let productPrice = rowData.productPrice;
      formData.append("productPrice", productPrice);

      let productPriceWithoutStone = rowData.productPriceWithoutStone;
      formData.append("priceWithoutStone", productPriceWithoutStone);

      let coverImage = rowData.coverImage;
      formData.append("coverImage", coverImage);

      let productImages = rowData.productImages;
      formData.append("productImages", productImages);

      let combinationVideo = rowData.combinationVideo;
      formData.append("combinationVideo", combinationVideo);

      let shortDescription = rowData.shortDescription;
      formData.append("shortDescription", shortDescription);

      let productInformation = rowData.productInformation;
      formData.append("productInformation", productInformation);

      let productMeasurement = rowData.productMeasurement;
      formData.append("productMeasurement", rowData.productMeasurement);

      let roductMeasurementImage = rowData.roductMeasurementImage;
      formData.append("productMeasurementImage", roductMeasurementImage);

      formData.append("isActive", document.getElementById("is-active").value);

      // for (const pair of formData.entries()) {
      //   const [key, value] = pair;
      //   console.log(`${key}: ${value}`);
      // }

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status == "true") {
        alert("Combination Added Successfully!");
      } else {
        alert("Some Error Occured!");
      }
    } else {
      if (!rowData.productId) alert("Product ID is Required");
      else if (!rowData.combinationId) alert("Combination ID is required");
      else if (!rowData.productName) alert("Product name is required");
      else if (!rowData.productPrice) alert("Product Price is required");
    }
  };
  return (
    <>
      {/* Scroll Paper Modal */}
      <React.Fragment>
        <Dialog
          open={openScrollPaper}
          onClose={toggleScrollModal}
          scroll={"paper"}
        >
          <DialogTitle id="scroll-dialog-title">{scrollModalTitle}</DialogTitle>
          <DialogContent dividers>
            {scrollModalColumns &&
            (modalOpenFor === "metal" || modalOpenFor === "shape") ? (
              <DataGrid
                rows={scrollModalRows}
                columns={scrollModalColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                // rowSelectionModel={getCheckedRows}
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = scrollModalRows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  getSelectedRows(selectedRowData);
                }}
              />
            ) : (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddInput()}
                  style={{
                    float: "right",
                  }}
                >
                  Add New
                </Button>
                <br />
                <br />
                <div id="center-stone-carat-div">
                  {/* Render existing input boxes */}
                  {[...Array(centerStoneInputCount)].map((_, index) => (
                    <div className="row ">
                      <input
                        key={index}
                        type="text"
                        style={{ height: "40px", width: "200px" }}
                        className="mt-2 center-stone-carat-input"
                        placeholder={`Add Center Stone Carat`}
                      />
                    </div>
                  ))}
                </div>

                <div id="stone-carat-div">
                  {/* Render existing input boxes */}
                  {[...Array(stoneInputCount)].map((_, index) => (
                    <div className="row ">
                      <input
                        key={index}
                        type="text"
                        style={{ height: "40px", width: "200px" }}
                        className="mt-2 stone-carat-input"
                        placeholder={`Add Stone Carat`}
                      />
                    </div>
                  ))}
                </div>

                <div id="stone-quality-div">
                  {/* Render existing input boxes */}
                  {[...Array(stoneQualityInputCount)].map((_, index) => (
                    <div className="row ">
                      <input
                        key={index}
                        type="text"
                        style={{ height: "40px", width: "200px" }}
                        className="mt-2 stone-quality-input"
                        placeholder={`Add Stone Quality`}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleScrollModal("cancel")}>Cancel</Button>
            <Button onClick={toggleScrollModal}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      {/* Modal */}
      <React.Fragment>
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={() => {}}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Add Product Details"}
          </DialogTitle>
          <DialogContent>
            <p style={{ color: "transparent" }}>
              This is a sample text for admin for the admin.This will be to
              cover space
            </p>
            <DialogContentText fullWidth>
              <div>
                <TextField
                  id="product-id-input"
                  label="Product ID"
                  variant="outlined"
                  error={isIdFilled ? false : true}
                  size="small"
                  onInput={(event) =>
                    setInitialFields("product-id", event.target.value)
                  }
                  fullWidth
                  helperText={isIdFilled ? null : "Product Id is required"}
                />
              </div>
              <div className="my-4">
                <TextField
                  id="product-category-input"
                  select
                  label="Category"
                  size="small"
                  error={isCategoryFilled ? false : true}
                  onChange={(event) =>
                    setInitialFields("product-category", event.target.value)
                  }
                  fullWidth
                  helperText={isCategoryFilled ? null : "Category is required"}
                >
                  {allCategories &&
                    allCategories.map((categoryObj) => (
                      <MenuItem
                        key={categoryObj.Category_Id}
                        value={categoryObj.Category_Name}
                      >
                        {categoryObj.Category_Name}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              <div>
                <Rating
                  name="half-rating"
                  defaultValue={productRating}
                  precision={0.5}
                  onChange={(event, newValue) =>
                    setInitialFields("product-rating", newValue)
                  }
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="success">
              Save and Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      {/* Product Information */}
      <Grid container spacing={2} className="my-2">
        <Grid container className="mt-3 mx-2">
          <Card>
            <h5 className="mx-3 my-3">Product Information</h5>
            <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
            <CardContent>
              <Grid>
                <Grid>
                  <TextField
                    id="read-only-product-id"
                    value={productId}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ marginRight: 20, marginBottom: 20 }}
                    disabled
                  />

                  <TextField
                    id="sku-id"
                    label="SKU-ID"
                    variant="outlined"
                    size="small"
                  />
                  <input type="text" id="is-active" defaultValue="1" hidden />
                </Grid>
                <Grid>
                  <TextField
                    id="read-only-product-id"
                    value={productCategory}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ marginRight: 20 }}
                    disabled
                  />
                  <TextField
                    id="outlined-basic"
                    label="Style"
                    variant="outlined"
                    size="small"
                    // style={{ marginTop: 20 }}
                  />
                </Grid>
              </Grid>
              <h6 className="mx-3">Combination Count: {combinationCount}</h6>
            </CardContent>
          </Card>
        </Grid>

        <Grid container className="mt-3 mx-2">
          <Card>
            <h5 className="mx-2 my-3">Options</h5>
            <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  onClick={(event) =>
                    toggleInfoAndColumn("matchingBand", event)
                  }
                  label="Is matching band available"
                />
                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  onClick={(event) => toggleInfoAndColumn("measurement", event)}
                  label="Is measurement available"
                />
                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  label="Is Shape Available"
                  onClick={(event) => toggleInfoAndColumn("shape", event)}
                  checked={isShapeAvl}
                />
                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  onClick={(event) => toggleInfoAndColumn("metal", event)}
                  label="Is Metal available"
                />
                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  onClick={(event) => toggleInfoAndColumn("stoneCarat", event)}
                  label="Is Stone Carat available"
                />
                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  onClick={(event) =>
                    toggleInfoAndColumn("centerStoneCarat", event)
                  }
                  label="Is Center Stone Carat available"
                />

                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  label="Is Stone Origin Available"
                  onClick={(event) => toggleInfoAndColumn("stoneOrigin", event)}
                />

                <FormControlLabel
                  control={<Checkbox color="success" size="small" />}
                  label="Is Stone Quality Available"
                  onClick={(event) =>
                    toggleInfoAndColumn("stoneQuality", event)
                  }
                />
                <FormControlLabel
                  style={{ marginBottom: "27px" }}
                  control={
                    <Checkbox color="success" size="small" defaultChecked />
                  }
                  onClick={(event) => toggleInfoAndColumn("isActive", event)}
                  label="Is Active"
                />
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Create Combinations */}
      <Grid container style={{ marginTop: "30px", marginLeft: "10px" }}>
        <Card>
          <h5 className="mx-3 my-3">Basic Information</h5>
          <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
          <CardContent>
            {isShapeAvl ? (
              <Grid>
                <h6 className="mx-2">
                  Shape: {selectedShapes && selectedShapes.length}
                </h6>
                <Grid className="mt-2 d-flex">
                  {selectedShapes &&
                    selectedShapes.map((shapeData) => (
                      <Grid
                        className="d-flex flex-column align-items-center"
                        style={{ marginRight: "15px" }}
                      >
                        <img
                          src={shapeData.shapeImage}
                          alt=""
                          style={{
                            verticalAlign: "bottom",
                            width: "60px",
                            height: "60px",
                          }}
                        />
                        <h6 style={{ fontSize: "13px", marginTop: "15px" }}>
                          {shapeData.shape}
                        </h6>
                      </Grid>
                    ))}

                  <HiOutlinePlusCircle
                    onClick={() => toggleScrollModal("shape")}
                    style={{
                      fontSize: "22px",
                      color: "#2e7d32",
                      cursor: "pointer",
                      width: "60px",
                      height: "60px",
                      marginLeft: "15px",
                    }}
                  />
                </Grid>
                <hr style={{ color: "grey", marginTop: "15px" }} />
              </Grid>
            ) : null}

            {isMetalAvl ? (
              <Grid style={{ marginTop: "15px" }}>
                <h6 className="mx-2">
                  Metal: {selectedMetals && selectedMetals.length}
                </h6>
                <Grid className="mt-2 d-flex align-items-start justify-content-start">
                  {selectedMetals &&
                    selectedMetals.map((metalData) => (
                      <Grid
                        className="d-flex flex-column align-items-center justify-content-center mt-1"
                        style={{ marginRight: "15px" }}
                      >
                        <span
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: metalData.metalColor,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {metalData.metalCarat}
                        </span>
                        <h6 style={{ fontSize: "13px", marginTop: "15px" }}>
                          {metalData.metal}
                        </h6>
                      </Grid>
                    ))}

                  <HiOutlinePlusCircle
                    onClick={() => toggleScrollModal("metal")}
                    style={{
                      fontSize: "22px",
                      color: "#2e7d32",
                      cursor: "pointer",
                      width: "60px",
                      height: "60px",
                      marginLeft: "15px",
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}

            <hr style={{ color: "grey", marginTop: "15px" }} />

            <Grid className="d-flex" style={{ marginTop: "15px" }}>
              {isCenterStoneCaratAvl ? (
                <Grid style={{ marginRight: "100px" }}>
                  <h6 className="mx-2">
                    Center Stone Carat:{" "}
                    {addedCenterStoneCarats && addedCenterStoneCarats.length}
                  </h6>
                  <Grid className="mt-2 d-flex align-items-start justify-content-start">
                    {addedCenterStoneCarats &&
                      addedCenterStoneCarats.map((centerStoneCarat) => (
                        <Grid
                          className="d-flex flex-column align-items-center justify-content-center mt-1"
                          style={{ marginRight: "15px" }}
                        >
                          <span
                            style={{
                              width: "50px",
                              height: "50px",
                              border: "2px solid grey",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {centerStoneCarat}
                          </span>
                        </Grid>
                      ))}

                    <HiOutlinePlusCircle
                      onClick={() => toggleScrollModal("center-stone-carat")}
                      style={{
                        fontSize: "22px",
                        color: "#2e7d32",
                        cursor: "pointer",
                        width: "60px",
                        height: "60px",
                        marginLeft: "15px",
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {isStoneCaratAvl ? (
                <Grid>
                  <h6 className="mx-2">
                    Stone Carat: {addedStoneCarats && addedStoneCarats.length}
                  </h6>
                  <Grid className="mt-2 d-flex align-items-start justify-content-start">
                    {addedStoneCarats &&
                      addedStoneCarats.map((stoneCarat) => (
                        <Grid
                          className="d-flex flex-column align-items-center justify-content-center mt-1"
                          style={{ marginRight: "15px" }}
                        >
                          <span
                            style={{
                              width: "50px",
                              height: "50px",
                              border: "2px solid grey",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {stoneCarat}
                          </span>
                        </Grid>
                      ))}

                    <HiOutlinePlusCircle
                      onClick={() => toggleScrollModal("stone-carat")}
                      style={{
                        fontSize: "22px",
                        color: "#2e7d32",
                        cursor: "pointer",
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <hr />
            {isStoneOriginAvl ? (
              <Grid>
                <h6 className="mx-2 mb-2">Stone Origin</h6>
                <Button
                  variant="outlined"
                  className="mx-2"
                  color="success"
                  size="small"
                  id="natural-outlined"
                  onClick={() => toggleOriginButtons("natural-outlined")}
                >
                  Natural
                </Button>
                <Button
                  variant="contained"
                  className="mx-2"
                  color="success"
                  size="small"
                  id="natural-contained"
                  onClick={() => toggleOriginButtons("natural-contained")}
                  style={{ display: "none" }}
                >
                  Natural
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  id="lab-grown-outlined"
                  onClick={() => toggleOriginButtons("lab-grown-outlined")}
                >
                  Lab Grown
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  id="lab-grown-contained"
                  onClick={() => toggleOriginButtons("lab-grown-contained")}
                  style={{ display: "none" }}
                >
                  Lab Grown
                </Button>
              </Grid>
            ) : null}
            <hr />
            {isStoneQualityAvl ? (
              <Grid>
                <h6 className="mx-2 mb-2">
                  Stone Quality: {addedStoneQuality && addedStoneQuality.length}
                </h6>
                {addedStoneQuality &&
                  addedStoneQuality.map((stoneQuality) => (
                    <span
                      style={{
                        padding: "5px 15px",
                        border: "1px solid #ececec",
                        borderRadius: "20px",
                      }}
                      className="mx-2"
                    >
                      {stoneQuality}
                    </span>
                  ))}
                <HiOutlinePlusCircle
                  onClick={() => toggleScrollModal("stoneQuality")}
                  style={{
                    fontSize: "22px",
                    color: "#2e7d32",
                    cursor: "pointer",
                    width: "60px",
                    height: "60px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            ) : null}
          </CardContent>
        </Card>
      </Grid>

      {/* Insert Combinations */}
      <div
        style={{
          height: 400,
          width: "80vw",
          marginTop: "30px",
          marginLeft: "10px",
        }}
      >
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={dataGridRows}
            columns={dataGridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </>
  );
};

export default AddProduct;
