import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { MdDeleteOutline, MdModeEdit } from "react-icons/md";
import { RiEyeLine } from "react-icons/ri";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { FaLocationDot } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { ImPrinter } from "react-icons/im";
import Dialog from "@mui/material/Dialog";
import { FaRegCalendarAlt } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { api } from "../../apiCall";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Orders = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [orderCombinations, setOrderCombinations] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currenCombinations, setCurrentCombinations] = useState(null);
  const [internalRows, setInternalRows] = useState([]);

  const handleClickOpen = (row_data) => {
    let selectedOrder = rows.find(
      (order) => order.Order_Id === row_data.orderId
    );

    console.log(selectedOrder, "rd");
    setCurrentOrder(selectedOrder);

    let currentCombinations = [];
    let internalRows = [];

    console.log(orderCombinations, "oc");
    orderCombinations.forEach((combination) => {
      if (combination.Order_Id === row_data.orderId)
        currentCombinations.push(combination);
    });

    currentCombinations.forEach((combination) => {
      let rowObj = {};
      rowObj["id"] = combination.Combination_Id;
      rowObj["productName"] = combination.Product_Name;
      rowObj["size"] = combination.Size;
      rowObj["quantity"] = combination.Quantity;
      rowObj["productAmount"] = combination.Product_Price;
      rowObj["totalAmount"] = combination.Total_Amount;

      internalRows.push(rowObj);
    });

    setInternalRows(internalRows);
    setOpen(true);
  };

  const handleClose = () => {
    if (isEditing) {
      const userConfirmed = window.confirm(
        "Your changes might not be saved. Do you wish to exit?"
      );
      if (userConfirmed) {
        setOpen(false);
        setIsEditing(false);
      }
    } else {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Perform save logic here
    setIsEditing(false);
    setOpen(false);
  };

  const getAllOrders = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=orders&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["Order"];
        setRows(result);
        setOrderCombinations(data["Order Combination"]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const deleteOrder = async (row_data) => {
    // metalId

    const confirmDelete = window.confirm("Do you want to delete this order?");
    if (confirmDelete) {
      let formData = new FormData();
      formData.append("method", "orders");
      formData.append("action", "delete");
      formData.append("orderId", row_data.orderId);

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status === true) {
        getAllOrders();
      } else {
        alert("Some Error Occured");
      }
    }
  };

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 200,
    },
    {
      field: "quantity",
      headerName: "Qunatity",
      width: 100,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 150,
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
    },
    {
      field: "dateOrdered",
      headerName: "Date Ordered",
      width: 200,
    },
    {
      field: "amountPaid",
      headerName: "Amount Paid",
      width: 200,
    },
    {
      field: "orderType",
      headerName: "Order Type",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            onClick={() => handleClickOpen(params.row)}
            style={{ cursor: "pointer", marginRight: "15px" }}
          >
            <RiEyeLine size={25} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteOrder(params.row)}
          >
            <MdDeleteOutline size={25} />
          </div>
        </>
      ),
    },
  ];
  const Ordercolumn = [
    {
      field: "id",
      headerName: "Id",
      width: 50,
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 300,
    },
    {
      field: "size",
      headerName: "Size",
      width: 100,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
    },
    {
      field: "productAmount",
      headerName: "Product Amount",
      width: 150,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
    },
  ];
  return (
    <>
      <Grid container className="mt-25">
        <Card>
          <h5 className="mx-3 my-3">Order</h5>
          <p className="mx-3 my-3">View, Edit or Delete a Order</p>
          <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
          <CardContent style={{ display: "flex" }}>
            <div>
              <Card sx={{ height: "100%", width: "100%" }}>
                {!loading ? (
                  <DataGrid
                    rows={rows.map((row) => ({
                      id: row.Id,
                      orderId: row.Order_Id,
                      orderStatus: row.Order_Status,
                      paymentStatus: row.Payment_Status,
                      dateOrdered: row.Date_Ordered,
                      amountPaid: row.Amount_Paid,
                      orderType: row.Order_Type,
                    }))} // Assuming "Id" is the unique identifier
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                ) : (
                  <CircularProgress />
                )}
              </Card>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {/* dialog */}
      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Order Details
              </Typography>
              <div>
                {isEditing ? (
                  <Button autoFocus color="inherit" onClick={handleSaveClick}>
                    Save
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <List>
            <Card className="m-5">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="mx-3 my-3">
                    <FaRegCalendarAlt />
                    <span className="mx-2">
                      {currentOrder ? currentOrder.Date_Ordered : ""}
                    </span>
                  </h5>
                  <p className="mx-3 my-3">
                    Order Id : {currentOrder ? currentOrder.Order_Id : ""}
                  </p>
                </div>

                <div className="d-flex mx-3">
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label="Payment Status"
                    fullWidth
                    value={currentOrder ? currentOrder.Payment_Status : ""}
                    style={{ marginRight: "15px" }}
                  >
                    <MenuItem key="Paid" value="Paid">
                      Paid
                    </MenuItem>
                    <MenuItem key="Not Paid" value="Not Paid">
                      Not Paid
                    </MenuItem>
                    <MenuItem key="Partial Paid" value="Partial Paid">
                      Partial Paid
                    </MenuItem>
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    size="small"
                    label="Order Status"
                    value={currentOrder ? currentOrder.Order_Status : ""}
                  >
                    <MenuItem key="Order Booked" value="Order Booked">
                      Order Booked
                    </MenuItem>
                    <MenuItem key="Order Confirmed" value="Order Confirmed">
                      Order Confirmed
                    </MenuItem>
                    <MenuItem key="Order Cancelled" value="Order Cancelled">
                      Order Cancelled
                    </MenuItem>
                    <MenuItem key="Out For Delivery" value="Out For Delivery">
                      Out For Delivery
                    </MenuItem>
                    <MenuItem
                      key="Delivery Date Changed"
                      value="Delivery Date Changed"
                    >
                      Delivery Date Changed
                    </MenuItem>
                  </TextField>
                  <ImPrinter size={40} className="mx-3" />
                </div>
              </div>
              <hr style={{ color: "grey", marginTop: "0px" }} />

              <CardContent
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="d-flex flex-column justify-content-between">
                  <div className="d-flex justify-content-between">
                    <Card>
                      <CardContent className="d-flex">
                        <CgProfile size={40} style={{ marginRight: "10px" }} />
                        <div>
                          <h5 className="mb-2">Customer</h5>
                          <h6 className="mb-2">User Name : Vinyak Thakur</h6>
                          <h6 className="mb-2">Mobile : 8268751772</h6>
                          <h6 className="mb-2">
                            Email : vinayak.thakur@axisbank.com
                          </h6>
                        </div>
                      </CardContent>
                    </Card>
                    <Card>
                      <CardContent className="d-flex">
                        <FaTruck size={40} style={{ marginRight: "10px" }} />
                        <div>
                          <h5 className="mb-2">Order Info</h5>
                          <h6 className="mb-2">Number Of Product : 3</h6>
                          <h6 className="mb-2">Order Number : 1</h6>
                          <h6 className="mb-2">Order Status : Delivered</h6>
                          <h6 className="mb-2">
                            New Delivery Date : 27/01/2023
                          </h6>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                  <div>
                    <DataGrid
                      rows={internalRows.map((row) => ({
                        id: row.id,
                        productName: row.productName,
                        size: row.size,
                        quantity: row.quantity,
                        productAmount: row.productAmount,
                        totalAmount: row.totalAmount,
                      }))} // Assuming "Id" is the unique identifier
                      columns={Ordercolumn}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      // checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </div>
                </div>
                <div>
                  <Card>
                    <div className="d-flex align-items-center">
                      <FaLocationDot size={30} />
                      <h5 className="mx-3 my-3">Deliver To</h5>
                    </div>

                    <p className="mx-3 my-3">
                      Address : {currentOrder ? currentOrder.Address : ""}
                    </p>
                    <hr style={{ color: "grey", marginTop: "0px" }} />
                    <h5 className="mx-3 my-3">Payment Info</h5>
                    <p className="mx-3 my-3">
                      Payment Mode :{" "}
                      {currentOrder ? currentOrder.Payment_Mode : ""}
                    </p>
                    <p className="mx-3 my-3">
                      Payment Status:{" "}
                      {currentOrder ? currentOrder.Payment_Status : ""}
                    </p>
                    <TextField
                      id="outlined-basic"
                      label="Amount Paid"
                      size="small"
                      variant="outlined"
                      defaultValue={
                        currentOrder ? currentOrder.Amount_Paid : ""
                      }
                      className="mx-3 mb-3"
                    />
                    <hr style={{ color: "grey", marginTop: "0px" }} />
                    <h5 className="mx-3 my-3">Additional Info</h5>
                    <TextField
                      id="outlined-basic"
                      label="Engraving Text"
                      variant="outlined"
                      className="mx-3 mb-3"
                    />
                    <TextField
                      id="outlined-basic"
                      label="Any Other Note"
                      variant="outlined"
                      className="mx-3 mb-3"
                    />
                  </Card>
                </div>
              </CardContent>
            </Card>
          </List>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Orders;
