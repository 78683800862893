import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}`;
}

const minDistance = 10;

function RangeSlider({ onSliderChange }) {
  const [minData, setMinData] = useState(0);
  const [maxData, setMaxData] = useState(0);

  const getFilterPrice = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=Filter&&action=minMaxPrice"
    )
      .then((response) => response.json())
      .then((data) => {
        const minPrice = parseInt(data["minPrice"]);
        const maxPrice = parseInt(data["maxPrice"]);
        setMinData(minPrice);
        setMaxData(maxPrice);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getFilterPrice();
  }, []);

  const [value, setValue] = useState([0, 1000]); // Initial range from 0 to 1000

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onSliderChange(newValue); // Call the parent callback with the new slider values
  };

  return (
    <Box>
      <Slider
        getAriaLabel={() => "Minimum distance"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={minData} // Setting minimum value dynamically
        max={maxData} // Setting maximum value dynamically
        step={1} // Adjust the step as needed
        marks={[
          { value: minData, label: `$${minData}` },
          { value: maxData, label: `$${maxData}` },
        ]} // Add marks for min and max prices
        disableSwap
        sx={{
          color: "#3bb77e", // Change color to #3bb77e
        }}
      />
    </Box>
  );
}

export default RangeSlider;
