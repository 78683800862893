import React from "react";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";
import { SERVER_URL, api } from "../../apiCall";

const CategoryCard = ({ categoryData }) => {
  const SERVER_URL = "http://visionelectricalsystems.com/";
  const fullImageUrl = SERVER_URL + categoryData.Category_Image;

  const navigate = useNavigate();
  const handleClick = () => {
    // Navigate to the ShopByCategory page and pass the categoryData as state
    navigate(`/shopbycategory`, { state: { categoryData } });
  };

  return (
    <>
      <div onClick={handleClick} className="card" style={{ cursor: "pointer" }}>
        <Image
          className="cardImage"
          src={fullImageUrl}
          alt={categoryData.Category_Name}
        />
        <h4 className="cardTitle">{categoryData.Category_Name}</h4>
      </div>
    </>
  );
};

export default CategoryCard;
