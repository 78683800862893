import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Card, Typography } from "@mui/material";

import Button from "@mui/material/Button";
import { SERVER_URL } from "../../apiCall";

const Setting = () => {
  const [settingData, setSettingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(false);
  const [uploadSlide1, setUploadSlide1] = useState(false);
  const [uploadSlide2, setUploadSlide2] = useState(false);
  const [uploadSlide3, setUploadSlide3] = useState(false);
  const [uploadBanner, setUploadBanner] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSlide1, setSelectedSlide1] = useState(null);
  const [selectedSlide2, setSelectedSlide2] = useState(null);
  const [selectedSlide3, setSelectedSlide3] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const inputRef = useRef(null);
  const inputSlide1 = useRef(null);
  const inputSlide2 = useRef(null);
  const inputSlide3 = useRef(null);
  const inputBanner = useRef(null);

  const loadSetting = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=settings&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setSettingData(result[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSetting();
  }, []);

  const handleSave = async () => {
    if (edit === false) {
      setEdit(true);
    } else {
      try {
        const formData = new FormData();
        let websiteName = document.getElementById("websiteName").value;
        let slide1Url = document.getElementById("slide1Url").value;
        let slide2Url = document.getElementById("slide2Url").value;
        let slide3Url = document.getElementById("slide3Url").value;
        formData.append("websiteName", websiteName);
        formData.append("slide1Url", slide1Url);
        formData.append("slide2Url", slide2Url);
        formData.append("slide3Url", slide3Url);

        const response = await fetch(
          SERVER_URL + "api.php?method=settings&&action=websiteName",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle successful upload
          console.log("Website Name Updated successfully!");
          loadSetting();

          // Alert user and initiate refresh
          alert(
            "Changes saved successfully. Page will be refreshed to apply the changes at all components !"
          );

          window.location.reload(); // Refresh the page after 5 seconds
        } else {
          // Handle error
          console.error("Website Name Update failed:", response.statusText);
        }
      } catch (error) {
        console.error("Website Name Update error:", error);
      }
    }
  };

  const handleLogoUpload = async () => {
    if (uploadLogo === false) {
      inputRef.current.click(); // Trigger the file picker
      setUploadLogo(!uploadLogo);
    } else {
      try {
        const formData = new FormData();
        formData.append("logo", selectedImage); // Assuming your server expects a field named "image"

        const response = await fetch(
          "http://visionelectricalsystems.com/api.php?method=settings&&action=logo",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle successful upload
          setUploadLogo(!uploadLogo);
          console.log("Image uploaded successfully!");
          loadSetting();
          // You might need to update the server-side data or UI here
        } else {
          // Handle error
          console.error("Image upload failed:", response.statusText);
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }
    }
  };

  const handleSlideUpload1 = async () => {
    if (uploadSlide1 === false) {
      inputSlide1.current.click(); // Trigger the file picker
      setUploadSlide1(!uploadSlide1);
    } else {
      try {
        const formData = new FormData();
        formData.append("slide", selectedSlide1); // Assuming your server expects a field named "image"
        formData.append("targetSlide", 1);

        const response = await fetch(
          "http://visionelectricalsystems.com/api.php?method=settings&&action=slider",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle successful upload
          setUploadSlide1(!uploadSlide1);
          console.log("Image uploaded successfully!");
          loadSetting();
          // You might need to update the server-side data or UI here
        } else {
          // Handle error
          console.error("Slide upload failed:", response.statusText);
        }
      } catch (error) {
        console.error("Slide upload error:", error);
      }
    }
  };

  const handleSlideUpload2 = async () => {
    if (uploadSlide2 === false) {
      inputSlide2.current.click(); // Trigger the file picker
      setUploadSlide2(!uploadSlide2);
    } else {
      try {
        const formData = new FormData();
        formData.append("slide", selectedSlide2); // Assuming your server expects a field named "image"
        formData.append("targetSlide", 2);

        const response = await fetch(
          "http://visionelectricalsystems.com/api.php?method=settings&&action=slider",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle successful upload
          setUploadSlide2(!uploadSlide2);
          console.log("Image uploaded successfully!");
          loadSetting();
          // You might need to update the server-side data or UI here
        } else {
          // Handle error
          console.error("Slide upload failed:", response.statusText);
        }
      } catch (error) {
        console.error("Slide upload error:", error);
      }
    }
  };

  const handleSlideUpload3 = async () => {
    if (uploadSlide3 === false) {
      inputSlide3.current.click(); // Trigger the file picker
      setUploadSlide3(!uploadSlide3);
    } else {
      try {
        const formData = new FormData();
        formData.append("slide", selectedSlide3); // Assuming your server expects a field named "image"
        formData.append("targetSlide", 3);

        const response = await fetch(
          "http://visionelectricalsystems.com/api.php?method=settings&&action=slider",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle successful upload
          setUploadSlide3(!uploadSlide3);
          console.log("Image uploaded successfully!");
          loadSetting();
          // You might need to update the server-side data or UI here
        } else {
          // Handle error
          console.error("Slide upload failed:", response.statusText);
        }
      } catch (error) {
        console.error("Slide upload error:", error);
      }
    }
  };
  const handleBannerUpload = async () => {
    if (uploadBanner === false) {
      inputBanner.current.click(); // Trigger the file picker
      setUploadBanner(!uploadBanner);
    } else {
      try {
        const formData = new FormData();
        formData.append("banner", selectedBanner); // Assuming your server expects a field named "image"

        const response = await fetch(
          "http://visionelectricalsystems.com/api.php?method=settings&&action=banner",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle successful upload
          setUploadBanner(!uploadBanner);
          console.log("Image uploaded successfully!");
          loadSetting();
          // You might need to update the server-side data or UI here
        } else {
          // Handle error
          console.error("Slide upload failed:", response.statusText);
        }
      } catch (error) {
        console.error("Slide upload error:", error);
      }
    }
  };

  return (
    <>
      <div style={{ maxWidth: "100%" }}>
        <div className="websiteSetting mt-3">
          <Typography fontSize={25} fontWeight="bold" className="my-3">
            Website Setting
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="d-flex align-items-center justify-content-between gap-5">
              <div className="slide3 d-flex flex-column align-items-center justify-content-center gap-3">
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : SERVER_URL + settingData.Website_Logo
                  } // Use selectedImage or server URL
                  alt={settingData.Website_Name}
                />
                <input
                  type="file"
                  ref={inputRef}
                  onChange={(event) => setSelectedImage(event.target.files[0])}
                  style={{ display: "none" }}
                />

                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={handleLogoUpload}
                >
                  {uploadLogo ? "Upload Logo" : "Change Logo"}
                </Button>
              </div>
              <TextField
                disabled={edit ? false : true}
                id="websiteName"
                variant="outlined"
                className="mb-3"
                label="Website Name"
                defaultValue={settingData.Website_Name}
              />
              <TextField
                disabled
                id=""
                variant="outlined"
                className="mb-3"
                label="Server URL"
                defaultValue={settingData.Server_Url}
                helperText="To Change Server Url Kindly Contact Admin"
              />
              <Button variant="outlined" size="large" onClick={handleSave}>
                {edit ? "SAVE" : "EDIT"}
              </Button>
            </div>
          )}
        </div>
        <div className="slider mt-3">
          <Typography fontSize={25} fontWeight="bold" className="my-3">
            Slider Setting
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="d-flex justify-content-between">
              <div className="slide1 d-flex flex-column align-items-center justify-content-center gap-3">
                <img
                  style={{ width: "200px", height: "150px" }}
                  src={
                    selectedSlide1
                      ? URL.createObjectURL(selectedSlide1)
                      : SERVER_URL + settingData.Slide1
                  } // Use selectedImage or server URL
                  alt="Slide 1"
                />
                <input
                  type="file"
                  ref={inputSlide1}
                  onChange={(event) => setSelectedSlide1(event.target.files[0])}
                  style={{ display: "none" }}
                />

                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleSlideUpload1}
                >
                  {uploadSlide1 ? "Upload Slide 1" : "Change Slide 1"}
                </Button>
                <TextField
                  disabled={edit ? false : true}
                  id="slide1Url"
                  variant="outlined"
                  className="mb-3"
                  label="Slide 1 Redirect Url"
                  defaultValue={settingData.Slide1_Redirect}
                  size="small"
                />
              </div>

              <div className="slide2 d-flex flex-column align-items-center justify-content-center gap-3">
                <img
                  style={{ width: "200px", height: "150px" }}
                  src={
                    selectedSlide2
                      ? URL.createObjectURL(selectedSlide2)
                      : SERVER_URL + settingData.Slide2
                  } // Use selectedImage or server URL
                  alt="Slide 2"
                />
                <input
                  type="file"
                  ref={inputSlide2}
                  onChange={(event) => setSelectedSlide2(event.target.files[0])}
                  style={{ display: "none" }}
                />
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleSlideUpload2}
                >
                  {uploadSlide2 ? "Upload Slide 2" : "Change Slide 2"}
                </Button>
                <TextField
                  disabled={edit ? false : true}
                  id="slide2Url"
                  variant="outlined"
                  className="mb-3"
                  label="Slide 2 Redirect Url"
                  defaultValue={settingData.Slide2_Redirect}
                  size="small"
                />
              </div>

              <div className="slide3 d-flex flex-column align-items-center justify-content-center gap-3">
                <img
                  style={{ width: "200px", height: "150px" }}
                  src={
                    selectedSlide3
                      ? URL.createObjectURL(selectedSlide3)
                      : SERVER_URL + settingData.Slide3
                  } // Use selectedImage or server URL
                  alt="Slide 3"
                />
                <input
                  type="file"
                  ref={inputSlide3}
                  onChange={(event) => setSelectedSlide3(event.target.files[0])}
                  style={{ display: "none" }}
                />
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleSlideUpload3}
                >
                  {uploadSlide3 ? "Upload Slide 3" : "Change Slide 3"}
                </Button>
                <TextField
                  disabled={edit ? false : true}
                  id="slide3Url"
                  variant="outlined"
                  className="mb-3"
                  label="Slide 3 Redirect Url"
                  defaultValue={settingData.Slide3_Redirect}
                  size="small"
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Typography fontSize={25} fontWeight="bold" className="my-3">
            Banner Setting
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className=" d-flex flex-column align-items-center justify-content-center gap-3">
              <img
                style={{ width: "100%", height: "300px" }}
                src={
                  selectedBanner
                    ? URL.createObjectURL(selectedBanner)
                    : SERVER_URL + settingData.Custom_Design_Image
                } // Use selectedImage or server URL
                alt="Slide 3"
              />
              <input
                type="file"
                ref={inputBanner}
                onChange={(event) => setSelectedBanner(event.target.files[0])}
                style={{ display: "none" }}
              />
              <Button
                variant="outlined"
                color="success"
                onClick={handleBannerUpload}
              >
                {uploadBanner
                  ? "Upload Custom Design Banner"
                  : "Change Custom Design Banner"}
              </Button>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Typography fontSize={25} fontWeight="bold" className="my-3">
            About Us Page Setting
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className=" d-flex flex-column align-items-center justify-content-center gap-3">
              <img
                style={{ width: "100%", height: "300px" }}
                src={
                  selectedBanner
                    ? URL.createObjectURL(selectedBanner)
                    : SERVER_URL + settingData.Custom_Design_Image
                } // Use selectedImage or server URL
                alt="Slide 3"
              />
              <input
                type="file"
                ref={inputBanner}
                onChange={(event) => setSelectedBanner(event.target.files[0])}
                style={{ display: "none" }}
              />
              <Button
                variant="outlined"
                color="success"
                onClick={handleBannerUpload}
              >
                {uploadBanner
                  ? "Upload Custom Design Banner"
                  : "Change Custom Design Banner"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Setting;
