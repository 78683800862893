import React, { useEffect, useState } from "react";
import Shape from "./Shape";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { RiEyeLine } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import { SERVER_URL, api } from "../../apiCall";
import { MdDeleteOutline } from "react-icons/md";
import { FormControl } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Style = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [selectedStyle, setSelectedStyle] = useState(false);
  const [allStyles, setAllStyles] = useState(null);
  const [styleImageToUpload, setStyleImageToUpload] = useState(null);
  const [selectedStyleImage, setSelectedStyleImage] = useState(null);

  const loadStyles = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=style&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        console.log(result, "res");
        setRows(result);
        setAllStyles(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadStyles();
  }, []);

  const columns = [
    {
      field: "styleId",
      headerName: "Style ID",
      width: 250,
    },
    {
      field: "categoryId",
      headerName: "Category ID",
      width: 250,
    },
    {
      field: "styleName",
      headerName: "Style Name",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={{ cursor: "pointer", marginRight: "15px" }}
            onClick={() => handleClickOpen(params.row)}
          >
            <RiEyeLine size={25} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <MdDeleteOutline
              size={25}
              onClick={() => deleteStyle(params.row)}
            />
          </div>
        </>
      ),
    },
  ];

  const createNewStyle = async () => {
    let formData = new FormData();
    formData.append("method", "style");
    formData.append("action", "insert");

    let styleName = document.getElementById("style-name").value;
    formData.append("styleName", styleName);

    let categoryId = document.getElementById("category-id").value;
    formData.append("categoryId", categoryId);

    if (imageToUpload) {
      formData.append("styleImage", imageToUpload);
    }

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      setLoading(true);
      document.getElementById("style-name").value = "";
      setSelectedImage(null);
      loadStyles();
    } else alert("Could not add style,some Error occured");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageToUpload(file);
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickOpen = (row_data) => {
    let styleFound = allStyles.find(
      (style) => style.Style_Id === row_data.styleId
    );
    if (styleFound) {
      console.log(styleFound, "here");
      setSelectedStyle(styleFound);
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (isEditing) {
      const userConfirmed = window.confirm(
        "Your changes might not be saved. Do you wish to exit?"
      );
      if (userConfirmed) {
        setOpen(false);
        setIsEditing(false);
      }
    } else {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleSaveClick = async () => {
    // Perform save logic here
    let formData = new FormData();

    formData.append("method", "style");
    formData.append("action", "update");

    let styleId = document.getElementById("style_id").value;
    formData.append("styleId", styleId);

    let categoryId = document.getElementById("category_id").value;
    formData.append("categoryId", categoryId);

    let styleName = document.getElementById("style_name").value;
    formData.append("styleName", styleName);

    if (styleImageToUpload) {
      formData.append("styleImage", styleImageToUpload);
    }

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      loadStyles();
    }

    setIsEditing(false);
    setOpen(false);
    setReadOnly(true);
  };

  const handleEditClick = () => {
    setReadOnly(false);
    setIsEditing(true);
  };

  const handleStyleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedStyleImage(e.target.result);
        setStyleImageToUpload(file);

        document.getElementById("default-shape-image").style.display = "none";
        document.getElementById("selected-shape-image").style.display = "block";
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteStyle = async (row_data) => {
    const confirmDelete = window.confirm("Do you want to delete this style?");
    if (confirmDelete) {
      let formData = new FormData();
      formData.append("method", "style");
      formData.append("action", "delete");
      formData.append("styleId", row_data.styleId);

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status === true) {
        loadStyles();
      } else {
        alert("Some Error Occured");
      }
    }
  };

  return (
    <>
      <Grid container className="mt-25">
        <Card>
          <h5 className="mx-3 my-3">Style</h5>
          <p className="mx-3 my-3">Add, Edit or delete a Style</p>
          <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
          <CardContent style={{ display: "flex" }}>
            <div xs={12} md={4}>
              <Card
                style={{
                  width: "300px",
                  marginRight: "20px",
                }}
              >
                <h5 className="mx-3 my-3 d-flex align-self-center">
                  Add Style
                </h5>
                <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
                <CardContent className="d-flex flex-column align-items-center">
                  {/* <TextField
                    id="outlined-basic"
                    label="Style Id"
                    variant="outlined"
                    className="mb-3"
                    fullWidth
                  /> */}

                  <TextField
                    id="style-name"
                    label="Style Name"
                    variant="outlined"
                    className="mb-3"
                    fullWidth
                  />
                  <TextField
                    id="category-id"
                    label="Category Id"
                    variant="outlined"
                    className="mb-3"
                    fullWidth
                  />
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <img
                      src={require("../../Asset/upload.png")}
                      alt="Category"
                    />
                  )}
                  <Input
                    id="image-picker"
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-picker">
                    {selectedImage ? (
                      <Button
                        component="span"
                        variant="outlined"
                        className="mt-2"
                      >
                        Change Image
                      </Button>
                    ) : (
                      <Button
                        component="span"
                        variant="outlined"
                        className="mt-2"
                      >
                        Upload Image
                      </Button>
                    )}
                  </label>
                  <Button variant="outlined" onClick={createNewStyle}>
                    SUBMIT
                  </Button>
                </CardContent>
              </Card>
            </div>

            <div xs={12} md={8}>
              <Card
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!loading ? (
                  <DataGrid
                    rows={rows.map((row) => ({
                      id: row.Id,
                      styleId: row.Style_Id,
                      categoryId: row.Category_Id,
                      styleName: row.Style_Name,
                    }))} // Assuming "Id" is the unique identifier
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                ) : (
                  <CircularProgress />
                )}
              </Card>
            </div>
          </CardContent>
        </Card>
      </Grid>

      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Style
              </Typography>
              <div className="">
                {isEditing ? (
                  <Button autoFocus color="inherit" onClick={handleSaveClick}>
                    Save
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="m-3 d-flex justify-content-between">
              {selectedStyle && selectedStyle.Style_Id ? (
                <TextField
                  id="style_id"
                  label="Style Id"
                  defaultValue={selectedStyle.Style_Id}
                  InputProps={{
                    disabled: true,
                  }}
                  size="small"
                />
              ) : null}

              {selectedStyle && selectedStyle.Category_Id ? (
                <TextField
                  id="category_id"
                  label="Category Id"
                  defaultValue={selectedStyle.Category_Id}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}

              {selectedStyle && selectedStyle.Style_Name ? (
                <TextField
                  id="style_name"
                  label="Style Name"
                  defaultValue={selectedStyle.Style_Name}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}

              {selectedStyle && selectedStyle.Style_Image ? (
                <div className="d-flex flex-column my-5 mx-2">
                  <img
                    src={
                      selectedStyle.Style_Image
                        ? SERVER_URL + selectedStyle.Style_Image
                        : require("../../Asset/upload.png")
                    }
                    alt="Selected"
                    style={{ maxWidth: "150px" }}
                    id="default-shape-image"
                  />
                  <img
                    src={
                      selectedStyleImage
                        ? selectedStyleImage
                        : require("../../Asset/upload.png")
                    }
                    alt="Selected"
                    style={{ maxWidth: "150px", display: "none" }}
                    id="selected-shape-image"
                  />

                  <Input
                    id="shape-image"
                    type="file"
                    onChange={handleStyleImageChange}
                    style={{ display: "none" }}
                    disabled={readOnly}
                  />
                  <label htmlFor="shape-image">
                    <Button
                      component="span"
                      variant="outlined"
                      className="mt-2"
                      disabled={readOnly}
                    >
                      Change Image
                    </Button>
                  </label>
                </div>
              ) : null}
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Style;
