import React, { useState } from "react";
import Header from "../Components/Elements/Header";
import Home from "./Pages/Home";
import Aboutus from "./Pages/Aboutus";
import Product from "./Pages/Product";
import Contactus from "./Pages/Contactus";
import Shop from "./Pages/Shop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import ShopByCategory from "./Pages/ShopByCategory";
import Admin from "./Admin/Admin";
import UploadExcel from "./Admin/UploadExcel";
import MyAccount from "./Pages/MyAccount";
import CustomOrder from "./Pages/CustomOrder";

const Routing = () => {
  const [currentProduct, setCurrentProduct] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [countInCart, setCountInCart] = useState(
    localStorage.getItem("total_in_cart")
      ? localStorage.getItem("total_in_cart")
      : 0
  );
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Header isLoggedIn={isLoggedIn} countInCart={countInCart} />
            }
          >
            <Route index element={<Home />} />
            <Route
              path="/shop"
              element={
                <Shop
                  setCurrentProduct={setCurrentProduct}
                  setCountInCart={setCountInCart}
                />
              }
            />
            <Route
              path="/product"
              element={
                <Product
                  currentProduct={currentProduct}
                  setCurrentProduct={setCurrentProduct}
                  setCountInCart={setCountInCart}
                />
              }
            />
            <Route
              path="/cart"
              element={
                <Cart
                  countInCart={countInCart}
                  setCountInCart={setCountInCart}
                />
              }
            />
            <Route path="/shopbycategory" element={<ShopByCategory />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/custom-order" element={<CustomOrder />} />
            <Route
              path="/checkout"
              element={<Checkout setCountInCart={setCountInCart} />}
            />
            <Route
              path="/login"
              element={<Login setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/myaccount" element={<MyAccount />} />
            {/* <Route path="*" element={<Error />} /> */}
          </Route>
          <Route path="/admin" element={<Admin />} />
          <Route path="/uploadexcel" element={<UploadExcel />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
