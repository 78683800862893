import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import CategoryCard from "../Elements/CategoryCard";
import { SERVER_URL, api } from "../../apiCall";

const CategorySlider = () => {
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    // Fetch data from your API endpoint
    fetch(SERVER_URL + "api.php?method=category&action=getAll")
      .then((response) => response.json())
      .then((data) => setCategoryData(data.result))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <Row>
          <Col>
            <h3 className="title">Shop By Category</h3>
          </Col>
        </Row>
        <Row className="cards">
          {categoryData &&
            categoryData.map((category, index) => (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={3}
                className="categoryCard d-flex justify-content-center align-items-center"
              >
                <CategoryCard key={index} categoryData={category} />
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default CategorySlider;
