import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button, Snackbar } from "@mui/material";
// import "bootstrap/dist/css/bootstrap.min.css";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import MenuItem from "@mui/material/MenuItem";
import StepLabel from "@mui/material/StepLabel";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";

const steps = ["Requirement", "Specification", "Submit"];

const App = () => {
  const baseUrl = "http://visionelectricalsystems.com/";
  const [images, setImages] = useState([]);
  const [metals, setMetals] = useState();
  const [shapes, setShapes] = useState();
  const inputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [engravingText, setEngravingText] = useState("");
  const [size, setSize] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [selectedShape, setSelectedShape] = useState();
  const [selectedMetal, setSelectedMetal] = useState();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleFiles = (files) => {
    const newImages = Array.from(files).map((file) => {
      return {
        name: file.name,
        url: URL.createObjectURL(file),
      };
    });

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleClearFields = () => {
    setEngravingText("");
    setSize("");
    setExpectedDeliveryDate("");
  };

  useEffect(() => {
    if (openSnackbar) {
      fetch(
        "http://visionelectricalsystems.com/api.php?method=orders&&action=customOrder"
      )
        .then((response) => response.json())
        .then((data) => {
          setSnackbarMessage("Order Booked Successful!");
          setOpenSnackbar(true);
        })
        .catch((error) => {
          setSnackbarMessage(`API call failed: ${error.message}`);
          setOpenSnackbar(true);
        });
    }
  }, [openSnackbar]);

  useEffect(() => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=metal&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        setMetals(data.result);
        console.log(metals);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=shape&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        setShapes(data.result);
        console.log(shapes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleShapeClick = (shapeName) => {
    setSelectedShape(shapeName);
  };

  const handleMetalClick = (metalName) => {
    setSelectedMetal(metalName);
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (
    <>
      <div style={{ margin: "25px" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              THANKYOU FOR SENDING US YOUR INSPIRATION
            </Typography>
            <h6>Your Custom Order Request Id is ABC123</h6>
            <p>
              Our Jewellery experts will be curating your perfect engagement
              rings for you. <br /> Within 4 working Days, You will get an
              product link for your product request. You can either buy it
              online or talk to our Specialist about your changes.
            </p>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Raise A New Custom Order</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 ? (
              <>
                <div className="d-flex flex-column">
                  <div className="pictureReference d-flex m-3">
                    <div style={{ width: "200px" }}>
                      <div
                        style={{
                          border: "2px dashed #ccc",
                          padding: "50px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleImageClick}
                      >
                        <h6>Drag and Drop or Click to Select Images</h6>
                        {images.map((image, index) => (
                          <div key={index} style={{ margin: "10px" }}>
                            {image.name}
                          </div>
                        ))}
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                    </div>
                  </div>
                  <div className="url m-3">
                    <TextField
                      id="outlined-basic"
                      label="URL"
                      variant="outlined"
                      value={engravingText}
                      onChange={(e) => setEngravingText(e.target.value)}
                      style={{ marginRight: "25px" }}
                    />
                  </div>
                  <div className="detail m-3">
                    <p>Tell us what you want to modify or to keep the same</p>
                    <TextField
                      id="outlined-multiline-static"
                      label="Comments"
                      multiline
                      rows={1}
                      defaultValue="Need Without the side diamond replace the center diamond with 0.5 ct wt"
                    />
                    {/* <Textarea
                      aria-label="textarea"
                      placeholder="Need Without the side diamond replace the center diamond with 0.5 ct wt"
                    /> */}
                  </div>
                </div>
              </>
            ) : null}
            {activeStep === 1 ? (
              <>
                <div className="d-flex flex-column m-3">
                  <div className="metal">
                    <p>
                      <b>Metal Color :</b>
                    </p>
                    <div className="d-flex gap-3">
                      {metals &&
                        metals.map((metal, index) => (
                          <div
                            key={index}
                            className="metalCircle d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: metal.Metal_Color, // Use the dynamic color property
                              width: "50px",
                              height: "50px",
                              borderRadius: "50px",
                              fontWeight: "Bold",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMetalClick(metal.Metal_Name)}
                          >
                            {metal.Carat}
                          </div>
                        ))}
                    </div>
                    <TextField
                      id="outlined-basic"
                      // label="Selected Metal"
                      disabled
                      variant="outlined"
                      value={selectedMetal}
                      // onChange={(e) => setEngravingText(e.target.value)}
                      style={{ marginRight: "25px" }}
                    />
                  </div>
                  <div className="shape mb-3">
                    <p>
                      <b>Shape :</b>
                    </p>
                    <div className="d-flex gap-3">
                      {shapes &&
                        shapes.map((shape, index) => (
                          <Tooltip key={index} title={shape.Shape_Name}>
                            <div
                              className="metalCircle d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#fff",
                                width: "100px",
                                height: "100px",
                                borderRadius: "50px",
                                fontWeight: "Bold",
                              }}
                              onClick={() => handleShapeClick(shape.Shape_Name)}
                            >
                              <img
                                src={baseUrl + shape.Shape_Image}
                                alt={shape.Shape_Name}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </Tooltip>
                        ))}
                    </div>
                    <TextField
                      id="outlined-basic"
                      // label="Selected Shape"
                      disabled
                      variant="outlined"
                      value={selectedShape}
                      placeholder="Shape"
                      onChange={(e) => setSelectedShape(e.target.value)}
                      style={{ marginRight: "25px" }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="Ring Size"
                      type="number"
                      variant="outlined"
                      value={engravingText}
                      onChange={(e) => setEngravingText(e.target.value)}
                      style={{ marginRight: "25px" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Diamond Carat"
                      defaultValue="Carat 1"
                      helperText="Kindly Select a Diamond Carat Weight"
                    >
                      <MenuItem key="Carat 1" value="Carat 1">
                        Carat 1
                      </MenuItem>
                      <MenuItem key="Carat 2" value="Carat 2">
                        Carat 2
                      </MenuItem>
                    </TextField>
                  </div>
                </div>
              </>
            ) : null}
            {activeStep === 2 ? (
              <>
                <div className="d-flex m-3">
                  <div className="d-flex align-items-start justify-content-center">
                    <TextField
                      id="outlined-basic"
                      label="Full Name"
                      variant="outlined"
                      value={engravingText}
                      onChange={(e) => setEngravingText(e.target.value)}
                      style={{ marginRight: "25px" }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Mobile Number"
                      type="number"
                      variant="outlined"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                      style={{ marginRight: "25px" }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker label="Expeccted Delivery Date" />
                      </DemoContainer>
                    </LocalizationProvider>
                    {/* <TextField
                      id="outlined-basic"
                      label="Expected Delivery Date"
                      type="date"
                      variant="outlined"
                      value={expectedDeliveryDate}
                      onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                      helperText="NOTE : Minimum Waiting Time For Reply from Us with Your Order Detail is 4 Working Days"
                      style={{ marginRight: "25px" }}
                    /> */}
                    <Button
                      variant="contained"
                      className="mt-2"
                      onClick={() => {
                        handleClearFields();
                        setOpenSnackbar(true);
                      }}
                    >
                      SUBMIT REQUEST
                    </Button>
                    <Snackbar
                      open={openSnackbar}
                      onClose={handleCloseSnackbar}
                      message={snackbarMessage}
                      autoHideDuration={3000} // Customize duration
                    />
                  </div>
                </div>
              </>
            ) : null}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default App;
