import React, { useState } from "react";

const ThreeDHand = () => {
  const [sliderValue, setSliderValue] = useState(0);

  const albinoOpacity = 1 - sliderValue / 100;
  const darkOpacity = sliderValue / 100;

  return (
    <div>
      <input
        className="handSlider"
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={(e) => setSliderValue(Number(e.target.value))}
      />

      <div className="hand-container">
        <img
          className="hand-image albino"
          style={{ opacity: albinoOpacity }}
          src={require("../../Asset/3D_Hand/hand-1.webp")}
          alt="Albino Hand"
        />
        <img
          className="hand-image dark"
          style={{ opacity: darkOpacity }}
          src={require("../../Asset/3D_Hand/hand-2.webp")}
          alt="Dark Hand"
        />
        <img
          className="ring-image"
          src={require("../../Asset/3D_Hand/ring.png")}
          alt="Ring"
        />
      </div>
    </div>
  );
};

export default ThreeDHand;
