import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { MdModeEdit } from "react-icons/md";
import { RiEyeLine } from "react-icons/ri";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SERVER_URL, api } from "../../apiCall";
import CloseIcon from "@mui/icons-material/Close";
import { MdDeleteOutline } from "react-icons/md";
import {
  AppBar,
  Dialog,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Shape = () => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [allShapes, setAllShapes] = useState(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [shapeImageToUpload, setShapeImageToUpload] = useState(null);
  const [selectedShapeImage, setSelectedShapeImage] = useState(null);

  const loadShapes = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=shape&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        console.log(result, "res");
        setRows(result);
        setAllShapes(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadShapes();
  }, []);

  const columns = [
    {
      field: "shapeId",
      headerName: "Shape Id",
      width: 250,
    },
    {
      field: "categoryId",
      headerName: "Category Id",
      width: 250,
    },
    {
      field: "shapeName",
      headerName: "Shape Name",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={{ cursor: "pointer", marginRight: "15px" }}
            onClick={() => handleClickOpen(params.row)}
          >
            <RiEyeLine size={25} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <MdDeleteOutline
              size={25}
              onClick={() => deleteShape(params.row)}
            />
          </div>{" "}
        </>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageToUpload(file);
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const createNewShape = async () => {
    let formData = new FormData();
    formData.append("method", "shape");
    formData.append("action", "insert");

    let categoryId = document.getElementById("category-id").value;
    formData.append("categoryId", categoryId);

    let shapeName = document.getElementById("shape-name").value;
    formData.append("shapeName", shapeName);

    if (imageToUpload) {
      formData.append("image", imageToUpload);
    }

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      setSelectedImage(null);
      document.getElementById("shape-name").value = "";
      setLoading(true);
      loadShapes();
    } else alert("Could not add shape,some Error occured");
  };

  const handleClickOpen = (row_data) => {
    let shapeFound = allShapes.find(
      (shape) => shape.Shape_Id === row_data.shapeId
    );
    if (shapeFound) {
      console.log(shapeFound, "here");
      setSelectedShape(shapeFound);
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (isEditing) {
      const userConfirmed = window.confirm(
        "Your changes might not be saved. Do you wish to exit?"
      );
      if (userConfirmed) {
        setOpen(false);
        setIsEditing(false);
      }
    } else {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setReadOnly(false);
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    // Perform save logic here
    let formData = new FormData();

    formData.append("method", "shape");
    formData.append("action", "update");

    let shapeId = document.getElementById("shape_id").value;
    formData.append("shapeId", shapeId);

    let categoryId = document.getElementById("category_id").value;
    formData.append("categoryId", categoryId);

    let shapeName = document.getElementById("shape_name").value;
    formData.append("shapeName", shapeName);

    if (shapeImageToUpload) {
      formData.append("image", shapeImageToUpload);
    }

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      loadShapes();
    }
    setIsEditing(false);
    setOpen(false);
    setReadOnly(true);
  };
  const handleShapeImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedShapeImage(e.target.result);
        setShapeImageToUpload(file);

        document.getElementById("default-shape-image").style.display = "none";
        document.getElementById("selected-shape-image").style.display = "block";
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteShape = async (row_data) => {
    const confirmDelete = window.confirm("Do you want to delete this shape?");
    if (confirmDelete) {
      let formData = new FormData();
      formData.append("method", "shape");
      formData.append("action", "delete");
      formData.append("shapeId", row_data.shapeId);

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status === true) {
        loadShapes();
      } else {
        alert("Some Error Occured");
      }
    }
  };
  return (
    <>
      <Grid className="mt-25">
        <Card>
          <h5 className="mx-3 my-3">Shape</h5>
          <p className="mx-3 my-3">Add, Edit or delete a Shape</p>
          <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
          <CardContent style={{ display: "flex" }}>
            <div xs={12} md={4}>
              <Card
                style={{
                  width: "300px",
                  marginRight: "20px",
                }}
              >
                <h5 className="mx-3 my-3 d-flex align-self-center">
                  Add Shape
                </h5>
                <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
                <CardContent>
                  {/* <TextField
                    id="outlined-basic"
                    label="Shape Id"
                    variant="outlined"
                    className="mb-3"
                    fullWidth
                  /> */}

                  <TextField
                    id="category-id"
                    variant="outlined"
                    className="mb-3"
                    label="Category Id"
                    fullWidth
                  />

                  <TextField
                    id="shape-name"
                    variant="outlined"
                    className="mb-3"
                    label="Shape Name"
                    fullWidth
                  />
                  <FormControl fullWidth className="d-flex align-items-center">
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        src={require("../../Asset/upload.png")}
                        alt="Category"
                      />
                    )}
                    <Input
                      id="image-picker"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="image-picker">
                      {selectedImage ? (
                        <Button component="span" variant="outlined">
                          Change Image
                        </Button>
                      ) : (
                        <Button component="span" variant="outlined">
                          Upload Image
                        </Button>
                      )}
                    </label>
                    <Button variant="outlined" onClick={createNewShape}>
                      SUBMIT
                    </Button>
                  </FormControl>
                </CardContent>
              </Card>
            </div>

            <Grid xs={12} md={8}>
              <Card
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!loading ? (
                  <DataGrid
                    rows={rows.map((row) => ({
                      id: row.Id,
                      shapeId: row.Shape_Id,
                      categoryId: row.Category_Id,
                      shapeName: row.Shape_Name,
                    }))} // Assuming "Id" is the unique identifier
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                ) : (
                  <CircularProgress />
                )}
              </Card>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Shape
              </Typography>
              <div className="">
                {isEditing ? (
                  <Button autoFocus color="inherit" onClick={handleSaveClick}>
                    Save
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="m-3 d-flex justify-content-between">
              {selectedShape && selectedShape.Shape_Id ? (
                <TextField
                  id="shape_id"
                  label="Shape Id"
                  defaultValue={selectedShape.Shape_Id}
                  InputProps={{
                    disabled: true,
                  }}
                  size="small"
                />
              ) : null}

              {selectedShape && selectedShape.Category_Id ? (
                <TextField
                  id="category_id"
                  label="Category Id"
                  defaultValue={selectedShape.Category_Id}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}

              {selectedShape && selectedShape.Shape_Name ? (
                <TextField
                  id="shape_name"
                  label="Shape Name"
                  defaultValue={selectedShape.Shape_Name}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}

              {selectedShape && selectedShape.Shape_Image ? (
                <div className="d-flex flex-column my-5 mx-2">
                  <img
                    src={
                      selectedShape.Shape_Image
                        ? SERVER_URL + selectedShape.Shape_Image
                        : require("../../Asset/upload.png")
                    }
                    alt="Selected"
                    style={{ maxWidth: "150px" }}
                    id="default-shape-image"
                  />
                  <img
                    src={
                      selectedShapeImage
                        ? selectedShapeImage
                        : require("../../Asset/upload.png")
                    }
                    alt="Selected"
                    style={{ maxWidth: "150px", display: "none" }}
                    id="selected-shape-image"
                  />

                  <Input
                    id="shape-image"
                    type="file"
                    onChange={handleShapeImageChange}
                    style={{ display: "none" }}
                    disabled={readOnly}
                  />
                  <label htmlFor="shape-image">
                    <Button
                      component="span"
                      variant="outlined"
                      className="mt-2"
                      disabled={readOnly}
                    >
                      Change Image
                    </Button>
                  </label>
                </div>
              ) : null}
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Shape;
