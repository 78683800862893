import React, { useState } from "react";
import { api, SERVER_URL } from "../../apiCall";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Footer from "../Elements/Footer";
function Signup() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    let title = "";
    let text = "";
    let icon = "";
    let confirmButtonText = "";
    let confirmButtonColor = "";

    if (apiResponse.status === true) {
      title = "Awesome!";
      text =
        "Your account has been successfully created. Please click the button below to LogIn";
      icon = "success";
      confirmButtonText =
        "<a href='/login' style='text-decoration:none; color:white'>Login</a>";
      confirmButtonColor = "#4caf50";
    } else if (apiResponse.status === false) {
      title = "Oops!";
      text =
        "Entered email is already in use. Please try again by using a different email.";
      icon = "error";
      confirmButtonText = "OK";
      confirmButtonColor = "#ef5350";
    }

    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirmButtonColor,
      focusConfirm: false,
      to: "/login",
    });
  };

  return (
    <>
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="login_wrap widget-taber-content background-white">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h1 className="mb-5">Create an Account</h1>
                        <p className="mb-30">
                          Already have an account?
                          <Link to="/login"> Login</Link>
                        </p>
                      </div>
                      <form method="POST" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            required
                            name="method"
                            placeholder="Method"
                            value="register"
                            hidden
                          />
                        </div>
                        <div className="form-group">
                          <input
                            required
                            type="password"
                            name="password"
                            placeholder="Password"
                          />
                        </div>
                        <div className="form-group mb-30">
                          <button
                            type="submit"
                            className="btn btn-fill-out btn-block hover-up font-weight-bold mt-5"
                            name="login"
                          >
                            Submit &amp; Register
                          </button>
                        </div>
                        <p className="font-xs text-muted">
                          <strong>Note:</strong>Your personal data will be used
                          to support your experience throughout this website, to
                          manage access to your account, and for other purposes
                          described in our privacy policy
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
