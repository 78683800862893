//Post API Call Function:
const api = async (requestMethod, apiURL, requestBody) => {
  const requestOptions = {
    method: requestMethod,
    body: requestBody ? requestBody : undefined,
  };

  let responseData = {};

  let response = await fetch(apiURL, requestOptions);
  if (response.status === 200) {
    responseData = await response.json();
  }

  return responseData;
};

const SERVER_URL = "http://visionelectricalsystems.com/";

export { api, SERVER_URL };
