import React from "react";
import { Card, CardContent } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const MyAccount = () => {
  const [showProfile, setShowProfile] = useState(true);
  const [showOrder, setShowOrder] = useState(false);
  const [rows, setRows] = useState([]);
  const handleProfileClick = () => {
    setShowProfile(true);
    setShowOrder(false);
  };
  const handleOrderClick = () => {
    setShowProfile(false);
    setShowOrder(true);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "orderId",
      headerName: "Order Id",
      width: 200,
    },
    {
      field: "orderAmount",
      headerName: "Order Amount",
      width: 150,
    },
    {
      field: "amountPaid",
      headerName: "Amount Paid",
      width: 150,
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 150,
    },
  ];

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-around"
        xs={12}
        md={4}
        style={{ marginTop: "250px" }}
      >
        <div className="d-flex flex-column md-4 xs-12">
          <Card
            style={{ marginBottom: "15px", cursor: "pointer" }}
            onClick={handleProfileClick}
          >
            <CardContent
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h5>Profile</h5>
            </CardContent>
          </Card>
          <Card
            style={{ marginBottom: "15px", cursor: "pointer" }}
            onClick={handleOrderClick}
          >
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h5>Order</h5>
            </CardContent>
          </Card>
        </div>
        <div xs={12} md={8} className="xs-12 md-8">
          {showProfile ? (
            <Card>
              <CardContent style={{ width: "300px" }}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  size="small"
                  fullWidth
                  variant="outlined"
                />
                <div className="d-flex mt-3">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    size="small"
                    style={{ marginRight: "15px" }}
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Mobile"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  size="small"
                  fullWidth
                  variant="outlined"
                />
              </CardContent>
            </Card>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 100]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MyAccount;
