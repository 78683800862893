import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MdCall } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  const [category, setCategory] = useState();
  useEffect(() => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=category&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setCategory(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <Container
        fluid
        className="m-0 p-0"
        style={{ backgroundColor: "black", color: "white" }}
      >
        <section className="section-padding footer-mid">
          <div className="container pt-15 pb-20">
            <div className="row">
              <div
                className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                <h4
                  className="widget-title d-flex justify-content-center align-items-center"
                  style={{ color: "white" }}
                >
                  SHOP
                </h4>
                <ul className="footer-list  mb-sm-5 mb-md-0 d-flex justify-content-center align-items-center flex-column">
                  {category &&
                    category.map((cat) => (
                      <li key={cat.Category_Id}>
                        <a>{cat.Category_Name}</a>
                      </li>
                    ))}
                </ul>
              </div>
              <div
                className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
              >
                <h4
                  className="widget-title d-flex justify-content-center align-items-center"
                  style={{ color: "white" }}
                >
                  OUR COMPANY
                </h4>
                <ul className="footer-list  mb-sm-5 mb-md-0 d-flex justify-content-center align-items-center flex-column">
                  <li>
                    <a href="#" style={{ color: "white" }}>
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ color: "white" }}>
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
              >
                <h4
                  className="widget-title d-flex justify-content-center align-items-center"
                  style={{ color: "white" }}
                >
                  HELP
                </h4>
                <ul className="footer-list  mb-sm-5 mb-md-0 d-flex justify-content-center align-items-center flex-column">
                  <li>
                    <a>Shipping & Return Policy</a>
                  </li>
                  <li>
                    <a>Privacy Policy</a>
                  </li>
                  <li>
                    <a>Warranty</a>
                  </li>
                </ul>
              </div>
              <div
                className="footer-link-widget widget-install-app col  wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <h4
                  className="widget-title d-flex justify-content-center align-items-center"
                  style={{ color: "white" }}
                >
                  BECOME A INSIDER
                </h4>
                <p
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "white" }}
                >
                  Be first to get access to design trends, promotions and
                  exclusive offers.
                </p>

                <div className="newsletter">
                  <div className="subsciber-inner">
                    <div className="newsletter-content">
                      <form className="form-subcriber d-flex">
                        <input type="email" placeholder="Your emaill address" />
                        <button className="btn" type="submit">
                          Subscribe
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="container pb-30  wow animate__animated animate__fadeInUp"
          data-wow-delay="0"
        >
          <div className="row align-items-center">
            <div className="col-12 mb-15">
              <div className="footer-bottom"></div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <p className="font-sm mb-0" style={{ color: "white" }}>
                &copy; 2024, <strong className="text-brand">Godjn </strong>{" "}
                Solution Pvt Ltd
                <br />
                All rights reserved
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
