import React, { useEffect, useState } from "react";
import Footer from "../Elements/Footer";
import StarRating from "../Design/StarRating";
import ToastMsg from "../Design/ToastMsg";
import { SERVER_URL } from "../../apiCall";
import { Link } from "react-router-dom";

function Cart(props) {
  const [cart, setCart] = useState(
    localStorage.getItem("currentCart")
      ? JSON.parse(localStorage.getItem("currentCart"))
      : null
  );

  const [showToast, setShowToast] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [msg, setMsg] = useState(null);
  const [subTotal, setSubTotal] = useState(0);

  const removeProduct = (product) => {
    console.log(product, "prod");
    let updatedCart = cart.filter((prod) => prod.Id !== product.Id);
    setCart(updatedCart);
    localStorage.setItem("currentCart", JSON.stringify(updatedCart));

    let total_in_cart = parseInt(localStorage.getItem("total_in_cart")) - 1;
    localStorage.setItem("total_in_cart", total_in_cart);
    props.setCountInCart(total_in_cart);
    setMsg("Product removed from cart");
    setShowToast(true);
    generateSubTotal();
  };

  const clearCart = () => {
    setCart(null);
    localStorage.setItem("currentCart", JSON.stringify([]));
    localStorage.setItem("total_in_cart", 0);
    props.setCountInCart(0);

    if (cart && cart.length > 0) {
      setMsg("Cart Cleared");
      setShowToast(true);
    }
    generateSubTotal();
  };

  const handleProductCount = (event, combination_id, product_id) => {
    let foundProduct = null;
    if (combination_id) {
      foundProduct = cart.find(
        (product) => product.Combination_Id === combination_id
      );
    } else {
      foundProduct = cart.find(
        (product) => product.Combination_Id === product_id
      );
    }

    let count_in_cart = foundProduct.count_in_cart;
    count_in_cart = event.target.value;
    foundProduct.count_in_cart = count_in_cart;

    localStorage.setItem("currentCart", JSON.stringify(cart));

    generateSubTotal();
  };

  const generateSubTotal = () => {
    let cart = JSON.parse(localStorage.getItem("currentCart"));
    let subTotal = 0;
    if (cart) {
      cart.forEach((product) => {
        if (product.Final_Price)
          subTotal += product.Final_Price * product.count_in_cart;
        else subTotal += product.Product_Price * product.count_in_cart;
      });
      setSubTotal(subTotal);
    }

    localStorage.setItem("subTotal", subTotal);
  };

  useEffect(() => {
    generateSubTotal();
  }, []);
  return (
    <div>
      <main className="main">
        <div className="container mb-80 mt-50">
          <div className="row">
            <div className="col-lg-8 mb-40">
              <h1 className="heading-2 mb-10">Your Cart</h1>
              <div className="d-flex justify-content-between">
                <h6 className="text-body">
                  There are{" "}
                  <span className="text-brand">
                    {props.countInCart ? props.countInCart : 0}
                  </span>{" "}
                  products in your cart
                </h6>
                <h6 className="text-body">
                  <a
                    href="#"
                    className="text-muted "
                    onClick={(e) => {
                      e.preventDefault();
                      clearCart();
                    }}
                  >
                    <i className="fi-rs-trash mr-5 text-danger" />
                    <span className="text-danger">Clear Cart</span>
                  </a>
                </h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="table-responsive shopping-summery">
                {cart && cart.length > 0 ? (
                  <table className="table table-wishlist">
                    <thead>
                      <tr className="main-heading">
                        <th className="custome-checkbox start pl-30">
                          {/* <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox11"
                          defaultValue
                        /> */}
                          {/* <label
                          className="form-check-label"
                          htmlFor="exampleCheckbox11"
                        /> */}
                        </th>
                        <th scope="col" colSpan={2}>
                          Product
                        </th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Quantity</th>
                        {/* <th scope="col"> Subtotal</th> */}
                        <th scope="col" className="end">
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((product) => (
                        <>
                          <tr className="pt-30">
                            {/* <td className="custome-checkbox pl-30">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id="exampleCheckbox1"
                                defaultValue
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheckbox1"
                              />
                            </td> */}
                            <td className="image product-thumbnail pt-40 pl-30">
                              <img
                                src={
                                  product.Cover_Image
                                    ? SERVER_URL + product.Cover_Image
                                    : "assets/imgs/shop/product-1-1.jpg"
                                }
                                alt="#"
                              />
                            </td>
                            <td className="product-des product-name">
                              <h6 className="mb-5 mx-3">
                                <a
                                  className="product-name mb-10 text-heading"
                                  href="shop-product-right.html"
                                >
                                  {product.Product_Name}
                                </a>
                              </h6>
                              {product.Product_Rating &&
                              product.Product_Rating !==
                                "Product Is Not Yet Rated" ? (
                                <div className="product-rate-cover mx-3">
                                  <StarRating
                                    value={product.Product_Rating}
                                  ></StarRating>
                                  <span
                                    className="font-small ml-5 text-muted"
                                    style={{ verticalAlign: "text-bottom" }}
                                  >
                                    {" "}
                                    ({product.Product_Rating})
                                  </span>
                                </div>
                              ) : null}
                            </td>
                            {/* <td className="price" data-title="Price">
                            <h4 className="text-body">
                              ${product.Product_Price}{" "}
                            </h4>
                          </td> */}
                            <td className="price" data-title="Price">
                              <h4 className="text-brand">
                                $
                                {product.Final_Price
                                  ? product.Final_Price
                                  : product.Product_Price}{" "}
                              </h4>
                            </td>
                            <td
                              className="text-center detail-info"
                              data-title="Stock"
                            >
                              <div className="detail-extralink mr-15">
                                <div className="detail-qty border radius">
                                  {/* <a href="#" className="qty-down">
                                  <i className="fi-rs-angle-small-down" />
                                </a> */}
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="qty-val"
                                    defaultValue={product.count_in_cart}
                                    onChange={(event) =>
                                      handleProductCount(
                                        event,
                                        product.Combination_Id,
                                        product.Product_Id
                                      )
                                    }
                                    min={1}
                                  />
                                  {/* <a href="#" className="qty-up">
                                  <i className="fi-rs-angle-small-up" />
                                </a> */}
                                </div>
                              </div>
                            </td>

                            <td
                              className="action text-center"
                              data-title="Remove"
                            >
                              <a
                                href="#"
                                className="text-body"
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeProduct(product);
                                }}
                              >
                                <i className="fi-rs-trash text-danger" />
                              </a>
                            </td>
                          </tr>
                          {product.matchingBandTaken === "Yes" ? (
                            <>
                              <tr className="pt-30">
                                {/* <td className="custome-checkbox pl-30">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox"
                                    id="exampleCheckbox1"
                                    defaultValue
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheckbox1"
                                  />
                                </td> */}
                                <td className="image product-thumbnail pt-40 pl-30">
                                  <img
                                    src={
                                      product.Matching_Band_Image
                                        ? SERVER_URL +
                                          product.Matching_Band_Image
                                        : "assets/imgs/shop/product-1-1.jpg"
                                    }
                                    alt="#"
                                  />
                                </td>
                                <td className="product-des product-name">
                                  <h6 className="mb-5 mx-3">
                                    <a
                                      className="product-name mb-10 text-heading"
                                      href="shop-product-right.html"
                                    >
                                      {product.Matching_Band_Name}
                                    </a>
                                  </h6>
                                  {/* {product.Product_Rating &&
                                  product.Product_Rating !==
                                    "Product Is Not Yet Rated" ? (
                                    <div className="product-rate-cover mx-3">
                                      <StarRating
                                        value={product.Product_Rating}
                                      ></StarRating>
                                      <span
                                        className="font-small ml-5 text-muted"
                                        style={{ verticalAlign: "text-bottom" }}
                                      >
                                        {" "}
                                        ({product.Product_Rating})
                                      </span>
                                    </div>
                                  ) : null} */}
                                </td>
                                {/* <td className="price" data-title="Price">
                            <h4 className="text-body">
                              ${product.Product_Price}{" "}
                            </h4>
                          </td> */}
                                <td className="price" data-title="Price">
                                  <h4 className="text-brand mr-30">
                                    $
                                    {product.Matching_Band_Price
                                      ? product.Matching_Band_Price
                                      : product.Matching_Band_Price}
                                  </h4>
                                </td>
                                <td
                                  className="text-center detail-info"
                                  data-title="Stock"
                                >
                                  <div className="detail-extralink mr-15">
                                    <div className="detail-qty border radius">
                                      <input
                                        type="number"
                                        name="quantity"
                                        className="qty-val"
                                        defaultValue={product.count_in_cart}
                                        onChange={(event) =>
                                          handleProductCount(
                                            event,
                                            product.Combination_Id,
                                            product.Product_Id
                                          )
                                        }
                                        min={1}
                                      />
                                      {/* <a href="#" className="qty-up">
                                  <i className="fi-rs-angle-small-up" />
                                </a> */}
                                    </div>
                                  </div>
                                </td>

                                <td
                                  className="action text-center"
                                  data-title="Remove"
                                >
                                  <a
                                    href="#"
                                    className="text-body"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeProduct(product);
                                    }}
                                  >
                                    <i className="fi-rs-trash text-danger" />
                                  </a>
                                </td>
                              </tr>
                            </>
                          ) : null}
                        </>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>Your Cart is empty</p>
                )}
              </div>
              <div className="divider-2 mb-30" />
              <div className="cart-action d-flex justify-content-between">
                <a className="btn " href="/shop">
                  <i className="fi-rs-arrow-left mr-10" />
                  Continue Shopping
                </a>
                {/* <a className="btn  mr-10 mb-sm-15">
                  <i className="fi-rs-refresh mr-10" />
                  Update Cart
                </a> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="border p-md-4 cart-totals ml-30">
                <div className="table-responsive">
                  <table className="table no-border">
                    <tbody>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Subtotal</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">${subTotal}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td scope="col" colSpan={2}>
                          <div className="divider-2 mt-10 mb-10" />
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Shipping</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h5 className="text-heading text-end">Free </h5>
                        </td>
                      </tr>{" "}
                      {/* <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Estimate for</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h5 className="text-heading text-end">
                            United Kingdom{" "}
                          </h5>
                        </td>
                      </tr>{" "} */}
                      <tr>
                        <td scope="col" colSpan={2}>
                          <div className="divider-2 mt-10 mb-10" />
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Total</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">${subTotal}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Link to="/checkout" className="btn mb-20 w-100">
                  Proceed To CheckOut
                  <i className="fi-rs-sign-out ml-15" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastMsg
        showToast={showToast}
        setShowToast={setShowToast}
        severity={severity}
        msg={msg}
      />
      <Footer />
    </div>
  );
}

export default Cart;
