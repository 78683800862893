import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { MdDeleteOutline, MdModeEdit } from "react-icons/md";
import { RiEyeLine } from "react-icons/ri";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import { api } from "../../apiCall";
import {
  AppBar,
  Dialog,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Metal = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [allMetals, setAllMetals] = useState(null);
  const [selectedMetal, setSelectedMtal] = useState(null);

  const loadMetals = () => {
    fetch(
      "http://visionelectricalsystems.com/api.php?method=metal&&action=getAll"
    )
      .then((response) => response.json())
      .then((data) => {
        const result = data["result"];
        setRows(result);
        setAllMetals(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMetals();
  }, []);

  const columns = [
    {
      field: "metalId",
      headerName: "Metal Id",
      width: 250,
    },
    {
      field: "metalName",
      headerName: "Metal Name",
      width: 250,
    },
    {
      field: "metalColor",
      headerName: "Metal Color",
      width: 250,
    },
    {
      field: "metalCarat",
      headerName: "Metal Carat",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div style={{ cursor: "pointer", marginRight: "15px" }}>
            <RiEyeLine size={25} onClick={() => handleClickOpen(params.row)} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <MdDeleteOutline
              size={25}
              onClick={() => deleteMetal(params.row)}
            />
          </div>
        </>
      ),
    },
  ];

  const addNewMetal = async () => {
    let formData = new FormData();
    formData.append("method", "metal");
    formData.append("action", "insert");

    let metalName = document.getElementById("metal-name").value;
    formData.append("metalName", metalName);

    let metalColor = document.getElementById("metal-color").value;
    formData.append("metalColor", metalColor);

    let Carat = document.getElementById("metal-carat").value;
    formData.append("carat", Carat);

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      document.getElementById("metal-name").value = "";
      document.getElementById("metal-color").value = "";
      document.getElementById("metal-carat").value = "";
      setLoading(true);
      loadMetals();
    } else {
      alert("Could not insert metal");
    }
  };

  const handleClickOpen = (row_data) => {
    let metalFound = allMetals.find(
      (metal) => metal.Metal_Id === row_data.metalId
    );
    if (metalFound) {
      setSelectedMtal(metalFound);
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (isEditing) {
      const userConfirmed = window.confirm(
        "Your changes might not be saved. Do you wish to exit?"
      );
      if (userConfirmed) {
        setOpen(false);
        setIsEditing(false);
      }
    } else {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleSaveClick = async () => {
    let formData = new FormData();
    formData.append("method", "metal");
    formData.append("action", "update");

    let metalId = document.getElementById("metal_id").value;
    formData.append("metalId", metalId);

    let metalName = document.getElementById("metal_name").value;
    formData.append("metalName", metalName);

    let metalColor = document.getElementById("metal_color").value;
    formData.append("metalColor", metalColor);

    let Carat = document.getElementById("metal_carat").value;
    formData.append("carat", Carat);

    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    if (apiResponse.status === true) {
      loadMetals();
    }

    // Perform save logic here
    setIsEditing(false);
    setOpen(false);
    setReadOnly(true);
  };

  const handleEditClick = () => {
    setReadOnly(false);
    setIsEditing(true);
  };

  const deleteMetal = async (row_data) => {
    // metalId

    const confirmDelete = window.confirm("Do you want to delete this metal?");
    if (confirmDelete) {
      let formData = new FormData();
      formData.append("method", "metal");
      formData.append("action", "delete");
      formData.append("metalId", row_data.metalId);

      let apiResponse = await api(
        "POST",
        "http://visionelectricalsystems.com/api.php",
        formData
      );

      if (apiResponse.status === true) {
        loadMetals();
      } else {
        alert("Some Error Occured");
      }
    }
  };
  return (
    <>
      <Grid container className="mt-25">
        <Card>
          <h5 className="mx-3 my-3">Metal</h5>
          <p className="mx-3 my-3">Add, Edit or delete a Metal</p>
          <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
          <CardContent style={{ display: "flex" }}>
            <div xs={12} md={4}>
              <Card
                style={{
                  width: "280px",
                  marginRight: "20px",
                }}
              >
                <h5 className="mx-3 my-3 d-flex align-self-center">
                  Add Metal
                </h5>
                <hr style={{ color: "#d3d3d3", marginTop: "0px" }} />
                <CardContent className="d-flex flex-column align-items-center">
                  {/* <TextField
                    id="outlined-basic"
                    label="Metal Id"
                    variant="outlined"
                    className="mb-3"
                    size="small"
                    fullWidth
                  /> */}

                  <TextField
                    id="metal-name"
                    label="Metal Name"
                    variant="outlined"
                    className="mb-3"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="metal-color"
                    label="Metal Color"
                    variant="outlined"
                    className="mb-3"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    id="metal-carat"
                    label="Metal Carat"
                    variant="outlined"
                    className="mb-3"
                    size="small"
                    fullWidth
                  />
                  <Button variant="outlined" onClick={addNewMetal}>
                    SUBMIT
                  </Button>
                </CardContent>
              </Card>
            </div>

            <div xs={12} md={8}>
              <Card
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!loading ? (
                  <DataGrid
                    rows={rows.map((row) => ({
                      id: row.Id,
                      metalId: row.Metal_Id,
                      metalName: row.Metal_Name,
                      metalColor: row.Metal_Color,
                      metalCarat: row.Carat,
                    }))} // Assuming "Id" is the unique identifier
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                ) : (
                  <CircularProgress />
                )}
              </Card>
            </div>
          </CardContent>
        </Card>
      </Grid>

      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Metal
              </Typography>
              <div>
                {isEditing ? (
                  <Button autoFocus color="inherit" onClick={handleSaveClick}>
                    Save
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="m-3 d-flex justify-content-between">
              {selectedMetal && selectedMetal.Metal_Id ? (
                <TextField
                  id="metal_id"
                  label="Metal Id"
                  defaultValue={selectedMetal.Metal_Id}
                  InputProps={{
                    disabled: true,
                  }}
                  size="small"
                />
              ) : null}

              {selectedMetal && selectedMetal.Metal_Name ? (
                <TextField
                  id="metal_name"
                  label="Metal Name"
                  defaultValue={selectedMetal.Metal_Name}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}

              {selectedMetal && selectedMetal.Metal_Color ? (
                <TextField
                  id="metal_color"
                  label="Metal Color"
                  defaultValue={selectedMetal.Metal_Color}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}

              {selectedMetal && selectedMetal.Carat ? (
                <TextField
                  id="metal_carat"
                  label="Metal Carat"
                  defaultValue={selectedMetal.Carat}
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  size="small"
                />
              ) : null}
            </ListItem>
          </List>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default Metal;
