import React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

function StarRating(props) {
  return (
    <>
      <Rating
        name="read-only"
        value={props.value}
        precision={0.5}
        readOnly
        size="small"
      />
    </>
  );
}

export default StarRating;
