import React from "react";
import { api, SERVER_URL } from "../../apiCall";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Elements/Footer";

function Login(props) {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData(event.target);
    let apiResponse = await api(
      "POST",
      "http://visionelectricalsystems.com/api.php",
      formData
    );

    let text = "";
    let confirmButtonText = "";

    if (apiResponse.status === true) {
      // navigate("/");
      window.location.href = "/";
      localStorage.setItem("userId", apiResponse["User Data"].User_Id);
      localStorage.setItem("userName", apiResponse["User Data"].User_Name);
      localStorage.setItem("mobile", apiResponse["User Data"].Mobile);
      localStorage.setItem("email", apiResponse["User Data"].Email);
      localStorage.setItem("isLoggedIn", "true");
    } else {
      if (apiResponse.msg === "User Not Found") {
        text =
          "Entered email is not registered with us. Please click on the button below to SignUp.";
        confirmButtonText =
          "<a href='/signup' style='text-decoration:none; color:white'>Sign Up</a>";
      } else {
        text = "Incorrect Password. Please try again.";
        confirmButtonText = "Try Again";
      }
      Swal.fire({
        title: "Oops!",
        text: text,
        icon: "error",
        confirmButtonText: confirmButtonText,
        confirmButtonColor: "#ef5350",
      });
    }
  };
  return (
    <>
      <main className="main pages">
        <div className="page-content pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                <div className="row">
                  <div className="col-lg-6 pr-30 d-none d-lg-block">
                    <img
                      className="border-radius-15"
                      src={require("../../Asset/logingirl.png")}
                      alt
                    />
                  </div>
                  <div className="col-lg-6 col-md-8">
                    <div className="login_wrap widget-taber-content background-white">
                      <div className="padding_eight_all bg-white">
                        <div className="heading_s1">
                          <h1 className="mb-5">Login</h1>
                          <p className="mb-30">
                            Don't have an account?
                            <Link to="/signup"> Create here</Link>
                          </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                              type="text"
                              required
                              name="email"
                              placeholder="Email *"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              required
                              name="method"
                              value="login"
                              hidden
                            />
                          </div>
                          <div className="form-group">
                            <input
                              required
                              type="password"
                              name="password"
                              placeholder="Password *"
                            />
                          </div>
                          <div className="login_footer form-group mb-50">
                            <div className="chek-form"></div>
                            <a className="text-muted" href="#">
                              Forgot password?
                            </a>
                          </div>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-heading btn-block hover-up"
                              name="login"
                            >
                              Log in
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Login;
